<template>
  <div class="flex flex-col">
    <label :for="id" v-if="label" class="text-secondary mb-2">{{
      label
    }}</label>
    <input
      :id="id"
      :type="type"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder"
      :disabled="disabled"
      :required="required"
      :class="finalClass()"
    />
    <span class="mt-0 text-red text-xs" v-if="$slots.error">
      <slot name="error"></slot>
    </span>
  </div>
</template>

<script>
import { uid } from "uid";

export default {
  name: "InputField",
  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    id: function () {
      return uid();
    },
  },
  methods: {
    finalClass() {
      if (this.isValid)
        return (
          "w-full py-2 px-4 rounded-md border border-blue-light bg-white text-blue disabled:bg-light"
        );
      return (
        "w-full py-2 px-4 rounded-md border border-red bg-white text-blue disabled:bg-light"
      );
    },
  },
};
</script>