<template>
  <div
    class="
      flex
      items-center
      justify-between
      p-4
      px-5
      rounded-xl
      bg-white
      shadow
      text-blue
    "
  >
    <div>
      <h3 class="font-medium">License ID: {{ license.id }}</h3>
      <p class="text-sm text-secondary">
        Building: {{ (license.building && license.building.name) || "NaN" }}
      </p>
      <p v-if="user && user.isAdmin" class="text-sm mt-2">
        <strong>Company: </strong>
        <span class="font-light"
          >{{ license.customer.company }} - {{ license.customer.id }}</span
        >
      </p>
    </div>
    <div class="ml-auto mr-3 flex items-center">
      <div class="mx-1">
        <icon icon="license" size="6" />
      </div>
      <div class="mx-1">
        <span
          class="tag py-1 px-2 text-white rounded text-xs uppercase"
          :class="{
            'bg-green': license.status === 'active',
            'bg-secondary': license.status !== 'active',
          }"
          >{{ license.status }}</span
        >
      </div>
    </div>
    <div class="flex flex-col justify-end" v-if="user && !user.isAdmin">
      <div class="text-right">
        {{ licenseAmount }}
        <span class="text-xs">{{ "+VAT" }}</span>
      </div>
      <!-- <div class="text-right">{{ licenseAmountWithVAT}}

          <span class="text-xs ">{{"TOTAL"}}</span>
        </div> -->
      <small class="text-xs text-secondary" v-if="license.status == 'active'"
        >Expires at {{ expirationDate }}</small
      >
    </div>
    <div class="flex" v-if="user && user.isAdmin">
      <div class="flex flex-col justify-end">
        <div class="text-right">{{ licenseAmount }}</div>
        <small class="text-xs text-secondary"
          >Expires at {{ expirationDate }}</small
        >
      </div>
      <button
        class="
          py-2
          px-4
          ml-4
          flex
          items-center
          text-sm
          leading-3
          rounded-md
          border border-light-grey
          bg-light
          whitespace-nowrap
        "
        @click="$emit('edit', license.id)"
      >
        <icon icon="edit" size="4" />
        <span> Edit </span>
      </button>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { format, parseISO } from "date-fns";

import Icon from "@/components/Icon";

export default {
  name: "LicenseItem",
  components: {
    Icon,
  },
  props: {
    license: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    licenseAmount() {
      if (!this.license) {
        return null;
      }

      const num = new Intl.NumberFormat();

      return `${this.license.cost.symbol}${num.format(
        this.license.cost.amount
      )}`;
    },
    licenseAmountWithVAT() {
      if (!this.license) {
        return null;
      }

      const num = new Intl.NumberFormat();

      return `${this.license.cost.symbol}${num.format(
        (
          this.license.cost.amount *
          (1 + (this.license.cost.vat || 23) / 100)
        ).toFixed(0)
      )}`;
    },

    expirationDate() {
      if (!this.license) {
        return null;
      }
      // return format(parseISO(this.license.expires_at), 'MMM dd, yyyy')
      if (this.license.expires_at && this.license.expires_at != "-") {
        return format(parseISO(this.license.expires_at), "dd MMMM yyyy");
      }
      return null;
    },
    ...mapState(["user", "uid"]),
  },
  mounted() {
  },
};
</script>

<style>
/* * {
  border: 1px solid black;
} */
</style>
