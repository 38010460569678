<template>
  <div id="app" class="bg-light min-h-screen">
    <div v-if="loading" class="flex flex-col items-center justify-center min-h-screen w-full">
      <loading title="Loading..." />
    </div>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Layout/Loading'

export default {
  name: 'App',
  components: {
    Loading
  },
  data: () => ({
    loading: false
  }),
  mounted () {
    this.checkAuth();
  },
  computed: {
    ...mapState(['token', 'uid'])
  },
  methods : {
    checkAuth () {
      if (this.token && this.uid) {
        this.loading = true;
        this.getUser()
          .then(() => {
            this.$router.push('/');
            this.loading = false;
          })
          .catch(() => {
            this.logoutUser();
            this.$router.push('/login');
            this.loading = false;
          });
      }
    },
    ...mapActions(['getUser', 'logoutUser'])
  },
}
</script>