<template>
  <buildings-list v-if="user" />
  <!-- <admin-home v-else /> -->
</template>

<script>
import { mapState } from 'vuex'
import BuildingsList from '@/components/Buildings/BuildingsList'
// import AdminHome from '@/components/AdminHome'

export default {
  name: "DashboardHome",
  components: {
    BuildingsList,
    // AdminHome
  },
  computed: {
    ...mapState(['user', 'uid'])
  },
}
</script>