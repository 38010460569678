<template>
  <symbol
    id="icon-edit"
    viewBox="0 0 18 18"
  >
    <path d="M6.51797 15.1889H3.375C3.22582 15.1889 3.08275 15.1296 2.97726 15.0242C2.87177 14.9187 2.8125 14.7756 2.8125 14.6264V11.4834C2.81225 11.4104 2.8264 11.338 2.85415 11.2705C2.8819 11.2029 2.9227 11.1415 2.97422 11.0897L11.4117 2.65218C11.4641 2.59904 11.5265 2.55683 11.5953 2.52802C11.6641 2.49921 11.7379 2.48437 11.8125 2.48438C11.8871 2.48437 11.9609 2.49921 12.0298 2.52802C12.0986 2.55683 12.1609 2.59904 12.2133 2.65218L15.3492 5.78812C15.4024 5.84046 15.4446 5.90285 15.4734 5.97166C15.5022 6.04046 15.517 6.11431 15.517 6.1889C15.517 6.2635 15.5022 6.33735 15.4734 6.40615C15.4446 6.47496 15.4024 6.53734 15.3492 6.58968L6.91172 15.0272C6.85995 15.0787 6.79852 15.1195 6.73095 15.1473C6.66339 15.175 6.59101 15.1892 6.51797 15.1889V15.1889Z" stroke="currentColor" fill="transparent" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.5625 4.5L13.5 8.4375" stroke="currentColor" fill="transparent" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5312 6.46875L4.78125 13.2188" stroke="currentColor" fill="transparent" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.71484 15.1523L2.84766 11.2852" stroke="currentColor" fill="transparent" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>
</template>

<script>
export default {
  name: 'IconEdit'
}
</script>