const countries = {
  'IE': 'Ireland',
  'UK': 'United Kingdom',
  'DE': 'Germany',
  'FR': 'France',
  'US': 'USA',
  'AU': 'Australia',
  'KR': 'South Korea',
  'AL': 'Albania',
  'AT': 'Austria',
  'BE': 'Belgium',
  'BG': 'Bulgaria',
  'CH': 'Switzerland',
  'CY': 'Cyprus',
  'CZ': 'Czech Republic',
  'DK': 'Denmark',
  'EE': 'Estonia',
  'ES': 'Spain',
  'FI': 'Finland',
  'GR': 'Greece',
  'HR': 'Croatia',
  'HU': 'Hungary',
  'IT': 'Italy',
  'LI': 'Liechtenstein',
  'LT': 'Lithuania',
  'LU': 'Luxembourg',
  'LV': 'Latvia',
  'MC': 'Monaco',
  'MK': 'North Macedonia',
  'MT': 'Malta',
  'NL': 'Netherlands',
  'NO': 'Norway',
  'PL': 'Poland',
  'PT': 'Portugal',
  'RO': 'Romania',
  'RS': 'Serbia',
  'SE': 'Sweden',
  'SI': 'Slovenia',
  'SK': 'Slovakia',
  'TR': 'Turkey',
};

const areaUnits = [
  'm2',
  'ft2'
];
const buildingTypes = {
  'office': 'Office',
  'non-office': 'Non Office',
};

const licenseStatuses = {
  'active': 'Active',
  'pending': 'Pending payment',
}

module.exports = {
  countries,
  areaUnits,
  buildingTypes,
  licenseStatuses
};