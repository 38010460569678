<template>
  <symbol
    id="icon-energy"
    viewBox="0 0 18 18"
  >
    <path d="M6.75 16.875L7.875 11.25L3.375 9.5625L11.25 1.125L10.125 6.75L14.625 8.4375L6.75 16.875Z" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
  </symbol>
</template>

<script>
export default {
  name: 'IconEnergy'
}
</script>