<template>
  <symbol
    id="icon-account"
    viewBox="0 0 18 18"
  >
    <path d="M10.6875 7.875H13.5" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M10.6875 10.125H13.5" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M6.47656 10.125C7.40854 10.125 8.16406 9.36948 8.16406 8.4375C8.16406 7.50552 7.40854 6.75 6.47656 6.75C5.54458 6.75 4.78906 7.50552 4.78906 8.4375C4.78906 9.36948 5.54458 10.125 6.47656 10.125Z" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M4.29688 11.813C4.42079 11.329 4.70227 10.9 5.09692 10.5937C5.49158 10.2874 5.97697 10.1211 6.47656 10.1211C6.97616 10.1211 7.46155 10.2874 7.8562 10.5937C8.25086 10.9 8.53233 11.329 8.65625 11.813" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M15.1875 3.375H2.8125C2.50184 3.375 2.25 3.62684 2.25 3.9375V14.0625C2.25 14.3732 2.50184 14.625 2.8125 14.625H15.1875C15.4982 14.625 15.75 14.3732 15.75 14.0625V3.9375C15.75 3.62684 15.4982 3.375 15.1875 3.375Z" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
  </symbol>
</template>

<script>
export default {
  name: 'IconAccount'
}
</script>