<template>
  <symbol
    id="icon-billing"
    viewBox="0 0 18 18"
  >
    <path d="M2.25 15.1875V3.9375C2.25 3.78832 2.30926 3.64524 2.41475 3.53975C2.52024 3.43426 2.66332 3.375 2.8125 3.375H15.1875C15.3367 3.375 15.4798 3.43426 15.5852 3.53975C15.6907 3.64524 15.75 3.78832 15.75 3.9375V15.1875L13.5 14.0625L11.25 15.1875L9 14.0625L6.75 15.1875L4.5 14.0625L2.25 15.1875Z" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M10.125 7.875H13.5" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M10.125 10.125H13.5" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M7.875 6.75H4.5V11.25H7.875V6.75Z" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
  </symbol>
</template>

<script>
export default {
  name: 'IconBilling'
}
</script>