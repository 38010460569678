<template>
  <main class="cycle-dashboard py-20">
    <icon-library />
    <app-header />
    <div class="container max-w-7xl mx-auto">
      <bread-crumbs v-if="user " />
      <div class="cycle-dashboard-grid mt-10 grid grid-cols-12 gap-6">
        <div class="relative col-span-2" v-if="user ">
          <div class="sticky top-20">
            <navigation />
          </div>
        </div>
        <section :class="{
          'col-start-4 col-span-6': user ,
 
        }">
          <router-view></router-view>
        </section>
        <aside class="col-span-3" v-if="user "></aside>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex'

import Navigation from '@/components/Navigation'
import IconLibrary from '@/components/IconLibrary'
import AppHeader from '@/components/Header'
import BreadCrumbs from '@/components/Breadcrumbs'

export default {
  name: "UserDashboard",
  components: {
    Navigation,
    IconLibrary,
    AppHeader,
    BreadCrumbs
  },
  computed: {
    ...mapState(['user', 'uid'])
  }
}
</script>