<template>
  <loading v-if="loading" title="Loading licenses..."></loading>
  <div v-if="!loading">
    <header class="flex mb-6 justify-between">
      <div class="title">
        <h1 class="text-4xl text-blue font-medium mb-4">Manage Licenses</h1>
        <p class="font-light text-blue">Manage customer's licenses.</p>
      </div>
      <div class="actions grid gap-4 grid-flow-col auto-cols-auto">
        <input-field
          v-model="search"
          type="text"
          placeholder="Search by reference ID"
        />
        <dropdown
          v-if="!loading"
          :options="statusList"
          v-model="status"
          :disabled="loading"
          placeholder="Filter by status"
        />
      </div>
    </header>
    <div class="buildings-list grid gap-3">
      <license-list
        v-for="(license, index) in filteredLicenses"
        :key="index"
        :license="license"
        @edit="editLicense"
      />
    </div>
  </div>
  <modal-wrapper
    title="Edit License"
    v-if="editing && currentLicense"
    @close="closeEditLicense"
  >
    <template #content>
      <license-edit />
    </template>
    <template #actions>
      <button
        class="
          py-3
          px-4
          ml-2
          text-sm
          leading-3
          rounded-md
          shadow
          border border-green
          bg-green
          text-white
          whitespace-nowrap
          disabled:opacity-50 disabled:button-not-allowed
        "
        @click="updateCurrentLicense"
      >
        {{ saving ? "Saving..." : "Update License" }}
      </button>
    </template>
  </modal-wrapper>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { licenseStatuses } from "@/store/constants";

import Loading from "@/components/Layout/Loading";

import LicenseList from "@/components/Licenses/LicenseList";
// import LicenseItem from '@/components/Licenses/LicenseItem'
import LicenseEdit from '@/components/Licenses/LicenseEdit'
import ModalWrapper from "@/components/Layout/ModalWrapper";
import Dropdown from "@/components/Library/Dropdown";
import InputField from "@/components/Library/Input";

export default {
  name: "ManageLicenses",
  components: {
    Loading,
    LicenseList,
    // LicenseItem,
    LicenseEdit,
    ModalWrapper,
    Dropdown,
    InputField,
  },
  data() {
    return {
      loading: false,
      editing: false,
      saving: false,
      licenseStatuses,
      search: "",
    };
  },
  mounted() {
    this.getLicenses();
  },
  computed: {
    statusList() {
      let list = { "": "All Licenses" };

      return {
        ...list,
        ...licenseStatuses,
      };
    },
    status: {
      get() {
        return this.currentLicenseStatus;
      },
      set(value) {
        this.setCurrentLicenseStatus(value);
      },
    },
    filteredLicenses() {
      let licenses = this.licenses;

      if (this.currentLicenseStatus) {
        licenses = licenses.filter(
          (license) => license.status === this.currentLicenseStatus
        );
      }

      if (this.search !== "") {
        licenses = licenses.filter((license) => {
          let customerId = license.customer ? license.customer.id : "";
          let licenseId = license.id ? license.id : "";
          return customerId.toLowerCase().includes(this.search.toLowerCase()) ||
            licenseId.toLowerCase().includes(this.search.toLowerCase());
        });
      }

      return licenses;
    },
    ...mapState([
      "users",
      "uid",
      "licenses",
      "currentLicense",
      "currentLicenseStatus",
    ]),
  },
  methods: {
    editLicense(id) {
      this.editing = true;
      this.setCurrentLicense({
        ...this.licenses.find((license) => license.id === id),
      });
    },
    closeEditLicense() {
      this.editing = false;
      this.setCurrentLicense(null);
      this.setStartDate(null);
      this.setEndDate(null);
    },
    updateCurrentLicense() {
      this.saving = true;
      this.updateLicense().then(() => {
        this.saving = false;
        this.closeEditLicense();
      });
    },
    ...mapMutations([
      "setCurrentLicense",
      "setCurrentLicenseStatus",
      "setStartDate",
      "setEndDate",
    ]),
    ...mapActions(["updateLicense", "getLicenses"]),
  },
};
</script>