<template>
  <loading v-if="loading" title="Loading user..."></loading>
  <div v-if="!loading">
    <header class="mb-6">
      <h1 class="text-4xl text-blue font-medium mb-4">Client Details</h1>
      <p class="font-light text-blue">
        Update your contact details and company information.
      </p>
    </header>
    <section-wrapper>
      <section-header
        title="Personal Information"
        description="Edit the details of your company's designated contact for your Symphony Cycle license."
      >
        <template v-if="personalDetailsDisabled">
          <button
            class="
              py-3
              px-4
              text-sm
              leading-3
              rounded-md
              shadow
              border border-light-grey
              whitespace-nowrap
            "
            @click="personalDetailsDisabled = false"
          >
            Update Details
          </button>
        </template>
        <template v-else>
          <div class="flex">
            <button
              class="
                py-3
                px-4
                text-sm
                leading-3
                rounded-md
                shadow
                border border-light-grey
                whitespace-nowrap
              "
              @click="personalDetailsDisabled = true"
            >
              Cancel
            </button>
            <button
              class="
                py-3
                px-4
                ml-2
                text-sm
                leading-3
                rounded-md
                shadow
                border border-green
                bg-green
                text-white
                whitespace-nowrap
              "
              @click="updatePersonalDetails"
            >
              {{ savingPersonal ? "Saving..." : "Save" }}
            </button>
          </div>
        </template>
      </section-header>
      <div class="grid grid-cols-2 gap-8">
        <input-field
          :disabled="personalDetailsDisabled"
          v-model="firstName"
          label="First Name"
        ></input-field>
        <input-field
          :disabled="personalDetailsDisabled"
          v-model="lastName"
          label="Last Name"
        ></input-field>
        <input-field
          :disabled="personalDetailsDisabled"
          v-model="email"
          label="Email"
          type="email"
        ></input-field>
      </div>
    </section-wrapper>
    <section-wrapper>
      <section-header
        title="Account security"
        description="Edit your account password."
      >
        <template v-if="accountSecurityDisabled">
          <button
            class="
              py-3
              px-4
              text-sm
              leading-3
              rounded-md
              shadow
              border border-light-grey
              whitespace-nowrap
            "
            @click="accountSecurityDisabled = false"
          >
            Update Details
          </button>
        </template>
        <template v-else>
          <div class="flex">
            <button
              class="
                py-3
                px-4
                text-sm
                leading-3
                rounded-md
                shadow
                border border-light-grey
                whitespace-nowrap
              "
              @click="accountSecurityDisabled = true"
            >
              Cancel
            </button>
            <button
              class="
                py-3
                px-4
                ml-2
                text-sm
                leading-3
                rounded-md
                shadow
                border border-green
                bg-green
                text-white
                whitespace-nowrap
              "
              @click="updateAccountSecurity"
            >
              {{ savingSecurity ? "Saving..." : "Save" }}
            </button>
          </div>
        </template>
      </section-header>
      <div class="grid grid-cols-1 gap-3 w-1/2">
        <input-field
          :disabled="accountSecurityDisabled"
          v-model="oldPassword"
          label="Current Password"
          type="password"
        ></input-field>
        <input-field
          :disabled="accountSecurityDisabled"
          v-model="newPassword"
          label="New Password"
          type="password"
        >
          <template #error v-if="this.v$.newPassword.$invalid && !accountSecurityDisabled"
            >{{ v$.newPassword.$silentErrors[0].$message }}
          </template>
        </input-field>
      </div>
    </section-wrapper>
    <section-wrapper>
      <section-header
        title="Company Information"
        description="Edit your company information."
      >
        <template v-if="companyDetailsDisabled">
          <button
            class="
              py-3
              px-4
              text-sm
              leading-3
              rounded-md
              shadow
              border border-light-grey
              whitespace-nowrap
            "
            @click="companyDetailsDisabled = false"
          >
            Update Details
          </button>
        </template>
        <template v-else>
          <div class="flex">
            <button
              class="
                py-3
                px-4
                text-sm
                leading-3
                rounded-md
                shadow
                border border-light-grey
                whitespace-nowrap
              "
              @click="companyDetailsDisabled = true"
            >
              Cancel
            </button>
            <button
              class="
                py-3
                px-4
                ml-2
                text-sm
                leading-3
                rounded-md
                shadow
                border border-green
                bg-green
                text-white
                whitespace-nowrap
              "
              @click="updateCompanyDetails"
            >
              {{ savingCompany ? "Saving..." : "Save" }}
            </button>
          </div>
        </template>
      </section-header>
      <div class="grid grid-cols-2 gap-8">
        <div class="col-span-2">
          <input-field
            :disabled="companyDetailsDisabled"
            v-model="companyName"
            label="Company Name"
          ></input-field>
        </div>
        <input-field
          :disabled="companyDetailsDisabled"
          v-model="companyAddress1"
          label="Address 1"
        ></input-field>
        <input-field
          :disabled="companyDetailsDisabled"
          v-model="companyAddress2"
          label="Address 2"
        ></input-field>
        <input-field
          :disabled="companyDetailsDisabled"
          v-model="supportAgentCode"
          label="Support Agent Code"
        ></input-field>
      </div>
    </section-wrapper>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import SectionWrapper from "@/components/Layout/SectionWrapper";
import SectionHeader from "@/components/Layout/SectionHeader";
import Loading from "@/components/Layout/Loading";
import InputField from "@/components/Library/Input";

import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";

export default {
  name: "ClientDetails",
  components: {
    SectionWrapper,
    SectionHeader,
    Loading,
    InputField,
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  data: () => ({
    personalDetailsDisabled: true,
    companyDetailsDisabled: true,
    accountSecurityDisabled: true,
    loading: true,
    savingPersonal: false,
    savingCompany: false,
    savingSecurity: false,
    oldPassword: "",
    newPassword: "",
    currencies: {
      EUR: "Euro",
      GBP: "Pound Sterlin",
      AUSD: "Australian Dollar",
      USD: "US Dollar",
    },
  }),
  mounted() {
    if (!this.user) {
      this.getUser().then(() => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },
  validations() {
    return {
      code: {
        required,
      },
      newPassword: {
        required: helpers.withMessage("This field cannot be empty", required),
        minLength: minLength(6),
      },
    };
  },
  computed: {
    firstName: {
      get() {
        return this.user.representative_name.split(" ")[0];
      },
      set(value) {
        this.setFirstName(value);
      },
    },
    lastName: {
      get() {
        let surnames = this.user.representative_name.split(" ");
        return surnames.slice(1).join(" ");
      },
      set(value) {
        this.setLastName(value);
      },
    },
    email: {
      get() {
        return this.user.representative_email;
      },
      set(value) {
        this.setEmail(value);
      },
    },
    companyName: {
      get() {
        return this.user.company;
      },
      set(value) {
        this.setCompanyName(value);
      },
    },
    companyAddress1: {
      get() {
        return this.user.address1;
      },
      set(value) {
        this.setCompanyAddress1(value);
      },
    },
    companyAddress2: {
      get() {
        return this.user.address2;
      },
      set(value) {
        this.setCompanyAddress2(value);
      },
    },
    password: {
      get() {
        return this.user.password || null;
      },
      set(value) {
        this.setPassword(value);
      },
    },
    supportAgentCode: {
      get() {
        return this.user.client_support_agent_code || null;
      },
      set(value) {
        this.setSupportAgentCode(value);
      },
    },
    ...mapState(["user"]),
  },
  methods: {
    updatePersonalDetails() {
      this.savingPersonal = true;
      this.updateUser().then(() => {
        this.personalDetailsDisabled = true;
        this.savingPersonal = false;
      });
    },
    updateCompanyDetails() {
      this.savingCompany = true;
      this.updateUser()
        .then(() => {
          this.companyDetailsDisabled = true;
          this.savingCompany = false;
        })
        .catch((error) => {
          this.savingCompany = false;
          console.log(error);
        });
    },
    updateAccountSecurity() {
      this.savingSecurity = true;
      this.updatePassword({
        current_password: this.oldPassword,
        new_password: this.newPassword,
      })
        .then(() => {
          this.accountSecurityDisabled = true;
          this.savingSecurity = false;
        })
        .catch((error) => {
          this.savingSecurity = false;
          console.log(error);
        });
    },
    ...mapMutations([
      "setFirstName",
      "setLastName",
      "setEmail",
      "setCompanyName",
      "setCompanyAddress1",
      "setCompanyAddress2",
      "setPassword",
    ]),
    ...mapActions(["getUser", "updateUser", "updatePassword"]),
  },
};
</script>