export default {
  user: null,
  users: [],
  token: localStorage.getItem("sc_token") || null,
  uid: localStorage.getItem("sc_uid") || null,
  buildings: [],
  licenses: [],
  currentBuilding: null,
  newBuilding: {},
  buildCreatingStatus: false,
  currentLicense: null,
  currentLicenseStatus: "",
  newStartDate: null,
  newEndDate: null,
  creatingIsValid: true,
  editingIsValid: true,
}
