export default {
  setUser(state, user) {
    state.user = user;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setToken(state, token) {
    state.token = token;
  },
  setUid(state, uid) {
    state.uid = uid;
  },
  cleanAll(state) {
    state.user = null
    state = {
      user: null,
      users: [],
      token: localStorage.getItem("sc_token") || null,
      uid: localStorage.getItem("sc_uid") || null,
      buildings: [],
      licenses: [],
      currentBuilding: null,
      newBuilding: {},
      buildCreatingStatus: false,
      currentLicense: null,
      currentLicenseStatus: "",
      newStartDate: null,
      newEndDate: null,
      creatingIsValid: true,
      editingIsValid: true,
    }
  },
  setFirstName(state, firstName) {
    let name = state.user.representative_name.split(' ');
    name[0] = firstName;
    state.user.representative_name = name.join(' ');
  },
  setLastName(state, lastName) {
    const name = state.user.representative_name.split(' ');
    name[1] = lastName;

    state.user.representative_name = name.join(' ');

  },
  setEmail(state, email) {
    state.user.representative_email = email;
  },
  setCompanyName(state, companyName) {
    state.user.company = companyName;
  },
  setCompanyAddress1(state, companyAddress1) {
    state.user.address1 = companyAddress1;
  },
  setCompanyAddress2(state, companyAddress2) {
    state.user.address2 = companyAddress2;
  },
  setSupportAgentCode(state, supportAgentCode) {
    state.user.client_support_agent_code = supportAgentCode;
  },
  setBuildings(state, buildings) {
    state.buildings = buildings;
  },
  setCurrentBuilding(state, building) {
    state.currentBuilding = building;
  },
  setBuildingProp(state, { prop, value }) {
    state.currentBuilding[prop] = value;
  },
  setNewBuildingProp(state, { prop, value }) {
    state.newBuilding[prop] = value;
  },
  clearNewBuilding(state) {
    state.newBuilding = {};
  },
  SetCreatingIsValid(state, value) {
    state.creatingIsValid = value;
  },
  SetEditingIsValid(state, value) {
    state.editingIsValid = value;
  },
  refreshBuilding(state, building) {
    const index = state.buildings.findIndex(b => b.id === building.id);
    state.buildings.splice(index, 1, building);
  },
  setLicenses(state, licenses) {
    state.licenses = licenses;
  },
  setCurrentLicense(state, license) {
    state.currentLicense = license;
  },
  setLicenseProp(state, { prop, value }) {
    state.currentLicense[prop] = value;
  },
  refreshLicense(state, license) {
    const index = state.licenses.findIndex(l => l.id === license.id);
    state.licenses.splice(index, 1, license);
  },
  setCurrentLicenseStatus(state, status) {
    state.currentLicenseStatus = status;
  },
  setPassword(state, password) {
    state.user.password = password;
  },
  setStartDate(state, startDate) {
    state.newStartDate = startDate;
  },
  setEndDate(state, endDate) {
    state.newEndDate = endDate;
  },
}