<template>
  <div>
    <div class="-mx-4 -mt-2 mb-6">
      <tabs :tabs="tabs" v-model="currentTab" />
    </div>
    <template v-if="currentTab === 'details'">
      <h2 class="text-xl text-blue mb-6">License Details</h2>
      <div class="grid gap-6 grid-cols-2">
        <div>
          <h3 class="text-blue font-medium mb-2">License Id</h3>
          <p class="text-secondary font-light">{{ currentLicense.id }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">License Status</h3>
          <dropdown
            v-model="status"
            :options="licenseStatuses"
            :placeholder="'Select a status'"
          />
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Created at</h3>
          <p class="text-secondary font-light">{{ formattedCreatedAt }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Expires at</h3>
          <p class="text-secondary font-light">{{ formattedExpiresAt }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Payment Method:</h3>
          <p class="text-secondary font-light">
            {{ currentLicense.payment_method.type }}
          </p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">License cost</h3>
          <p class="text-secondary font-light">{{ formattedLicenseCost }}</p>
        </div>
      </div>
    </template>

    <template v-if="currentTab === 'customer'">
      <h2 class="text-xl text-blue mb-6">Customer Details</h2>
      <div class="grid gap-6 grid-cols-2">
        <div>
          <h3 class="text-blue font-medium mb-2">Customer ID</h3>
          <p class="text-secondary font-light">{{ customer.id }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Representative Name</h3>
          <p class="text-secondary font-light">
            {{ customer.representative_name }}
          </p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Representative Email</h3>
          <p class="text-secondary font-light">
            {{ customer.representative_email }}
          </p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Company</h3>
          <p class="text-secondary font-light">{{ customer.company }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Company currency</h3>
          <p class="text-secondary font-light">{{ customer.currency }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Support Agent Code</h3>
          <p class="text-secondary font-light">
            {{ customer.client_support_agent_code }}
          </p>
        </div>
      </div>
      <h2 class="text-xl text-blue my-6">Customer Address</h2>
      <div class="grid gap-4 grid-cols-2">
        <div class="col-span-2">
          <h3 class="text-blue font-medium mb-2">Address</h3>
          <p class="text-secondary font-light">
            {{ customer.address1 + ' ' + customer.address2 }}
          </p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">ZIP Code</h3>
          <p class="text-secondary font-light">{{ customer.zip }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">City</h3>
          <p class="text-secondary font-light">{{ customer.city }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Country / Region</h3>
          <p class="text-secondary font-light">{{ customer.country }}</p>
        </div>
      </div>
    </template>

    <template v-if="currentTab === 'building'">
      <h2 class="text-xl text-blue mb-6">Building Details</h2>
      <div class="grid gap-4 grid-cols-2">
        <div>
          <h3 class="text-blue font-medium mb-2">Building ID</h3>
          <p class="text-secondary font-light">{{ building.id }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Building Name</h3>
          <p class="text-secondary font-light">{{ building.name }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Address</h3>
          <p class="text-secondary font-light">
            {{ building.address1 + " " + building.address2 }}
          </p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Google Maps Link</h3>
          <p class="text-secondary font-light">
            <a
              :href="building.google_maps_link"
              target="_blank"
              class="text-blue underline"
              >Click here to open</a
            >
          </p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">ZIP Code</h3>
          <p class="text-secondary font-light">{{ building.zip }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Country</h3>
          <p class="text-secondary font-light">{{ countryLabel }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Building Type</h3>
          <p class="text-secondary font-light">{{ building.type }}</p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Days of operation per week</h3>
          <p class="text-secondary font-light">
            {{ building.normal_days_operation }} days
          </p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">Gross Building Area</h3>
          <p class="text-secondary font-light">
            {{ formatNumber(building.gross_building_area) }} m²
          </p>
        </div>
        <div>
          <h3 class="text-blue font-medium mb-2">
            Installed Fresh Air Ventilation Capacity
          </h3>
          <p class="text-secondary font-light">
            {{ formatNumber(building.fresh_air_ventilation_capacity) }} m³/s
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { format, parseISO } from "date-fns";

import { licenseStatuses, countries } from "@/store/constants";
// import InputField from '@/components/Library/Input'
import Dropdown from "@/components/Library/Dropdown";
import Tabs from "@/components/Layout/Tabs";

export default {
  name: "LicenseEdit",
  components: {
    // InputField,
    Dropdown,
    Tabs,
  },
  data: () => ({
    licenseStatuses,
    currentTab: "details",
    tabs: [
      {
        label: "License Details",
        value: "details",
      },
      {
        label: "Customer",
        value: "customer",
      },
      {
        label: "Building",
        value: "building",
      },
    ],
    countries,
  }),

  computed: {
    status: {
      get() {
        return this.currentLicense.status;
      },
      set(value) {
        this.setLicenseProp({
          prop: "status",
          value,
        });

        // create a shallow copy of the payment_method object inside currentLicense.
        // this is so that we can modify the payment_method object without mutating the original object.
        const paymentMethod = { ...this.currentLicense.payment_method };
        if (value === "pending") {
          paymentMethod.details.payed = false;
        } else {
          paymentMethod.details.payed = true;
          this.updateLicenseDates();
        }

        this.setLicenseProp({
          prop: "payment_method",
          value: paymentMethod,
        });
      },
    },
    customer() {
      return this.currentLicense.customer;
    },
    building() {
      return this.currentLicense.building;
    },
    startDate: {
      get() {
        return this.newStartDate || this.currentLicense.created_at;
      },
      set(value) {
        this.setStartDate(value);
      },
    },
    endDate: {
      get() {
        return this.newEndDate || this.currentLicense.expires_at;
      },
      set(value) {
        this.setEndDate(value);
      },
    },
    formattedCreatedAt() {
      // return format(parseISO(this.startDate), 'MMMM dd, yyyy')
      if (this.startDate && this.startDate != "-") {
        return format(parseISO(this.startDate), "dd MMMM yyyy");
      }

      return null;
    },
    formattedExpiresAt() {
      // return format(parseISO(this.endDate), "MMMM dd, yyyy");
      if (this.endDate && this.endDate != "-") {
        return format(parseISO(this.endDate), "dd MMMM yyyy");
      }

      return null;
    },
    formattedLicenseCost() {
      const num = new Intl.NumberFormat();

      return `${this.currentLicense.cost.symbol}${num.format(
        this.currentLicense.cost.amount
      )}${this.currentLicense.cost.unit}`;
    },
    countryLabel() {
      if (!this.building || !this.countries[this.building.country]) {
        return null;
      }

      return this.countries[this.building.country];
    },
    ...mapState(["currentLicense", "newStartDate", "newEndDate"]),
  },
  methods: {
    formatNumber(val) {
      const num = new Intl.NumberFormat();

      return `${num.format(val)}`;
    },
    updateLicenseDates() {
      const startDate = new Date();

      // EndDate is 1 year + a week after the StartDate.
      const endDate = new Date(
        startDate.getTime() +
          1000 * 60 * 60 * 24 * 365 * 1 +
          1000 * 60 * 60 * 24 * 7
      );

      this.startDate = format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
      this.endDate = format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
    },
    ...mapMutations(["setLicenseProp", "setStartDate", "setEndDate"]),
  },
};
</script>

<style></style>
