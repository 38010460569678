<template>
  <div class="w-full rounded-2xl border border-light-grey bg-white shadow p-6 mb-3">
    <slot />
  </div>
</template>

<script>
export default {
  name: "SectionWrapper",
}
</script>