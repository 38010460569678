<template>
  <div>
    <div v-if="isAnyLicenseActive">
      <header class="mb-6">
        <h1 class="text-4xl text-blue font-medium mb-4">
          Implement Symphony Cycle
        </h1>
        <p class="font-light text-blue">
          Programme your BMS/BAS to turn your waste heat into usable energy.
          Should you encounter any issues with implementation, please get in
          touch and our support team will provide guidance.
        </p>
      </header>
      <section-wrapper>
        <section-header title="Principle of Operation" condensed />
        <div class="text-blue font-light">
          <p class="mb-4">
            During cooler weather periods, Symphony Cycle re-purposes the
            cooling coil in an air handler to act as a heater. As normal, the
            cooling water gathers low grade heat from internal spaces or
            processes. Rather than having the cooler/chiller operate to release
            this heat, the warmed up cooling water is instead sent into the air
            handler’s cooling coil.
          </p>
          <p class="mb-4">
            The colder, fresh air entering the building then cools down the
            cooling water so it can go back to the internal spaces and collect
            more heat. The fresh air passing through the air handler is heated
            as it passes the cooling coil, thereby saving on heat otherwise
            needed from the air handler’s heating coil.
          </p>
          <p class="mb-4">
            Symphony Cycle saves on the cost to generate the cooling and heating
            it provides. It also offsets the costs to operate primary cooling
            water pumps, for standby operation of coolers/Chillers and
            heaters/boilers and where relevant, that of other supporting
            equipment such as cooling towers and condenser pumps.
          </p>
        </div>
      </section-wrapper>
      <section-wrapper>
        <section-header
          title="Symphony Cycle can be engaged when each of the following criteria is valid:"
          condensed
        />
        <div class="text-blue font-light">
          <p class="mb-4">
            1. There is a demand for cooling water cooling to indoor equipment
            such as fan coil units or chilled beams or for process cooling.
          </p>
          <p class="mb-4">
            2. Supply air that can be cooled or heated, is only being heated.
          </p>
          <p class="mb-4">
            3. The cooler that provides cooling to the indoor equipment also can
            provide cooling to the supply air.
          </p>
          <p class="mb-4">
            4. The temperature of the first air flow passing through the cooling
            coil is lower than the temperature of the cooling fluid in the
            cooling system.
          </p>
        </div>
      </section-wrapper>
      <section-wrapper>
        <section-header
          title="Activate the Supply Air Cooling Coil"
          condensed
        />
        <div class="text-blue font-light">
          <p class="mb-4">
            When the above conditions exist for Symphony Cycle, then the cooling
            coil can be activated. This process transfers waste heat from the
            cooling coil into the supply air while simultaneously cooling the
            cooling fluid. Consequently, less or no heat is required from the
            heater to heat the supply air, and less or no cooling is required
            from the cooler to cool the indoor equipment.
          </p>
        </div>
      </section-wrapper>
      <section-wrapper>
        <section-header title="Eliminated or Reduced Heater Demand" condensed />
        <div class="text-blue font-light">
          <p class="mb-4">
            Symphony Cycle can reduce or eliminate the supply air demand for
            heat from a heater.
          </p>
          <p class="mb-4">
            If no heat is required from the heater for the supply air, then the
            heating circuit to the supply air heater can be turned off. If there
            is no other heat demand from the heater, then the entire heating
            system can be turned off.
          </p>
          <p class="mb-4">
            If less heat is required for the supply air, then it may be possible
            to reduce the output from the pump that delivers the heat to the
            supply air and reduce the output of the heater.
          </p>
        </div>
      </section-wrapper>
      <section-wrapper>
        <section-header title="Eliminated or Reduced Cooler Demand" condensed />
        <div class="text-blue font-light">
          <p class="mb-4">
            Symphony Cycle can reduce or eliminate the indoor equipment demand
            for cooling from a cooler.<br />
          </p>
          <p class="mb-4">
            If no cooling is required from the cooler to meet the demand from
            the indoor equipment, then the cooler can be turned off but the
            pumps to the indoor equipment and the supply air remain in
            operation.
          </p>
          <p class="mb-4">
            If less cooling is required for the supply air, then it may be
            possible to reduce the output of the cooler.
          </p>
        </div>
      </section-wrapper>
      <section-wrapper>
        <section-header title="Managing a First Heating Coil" condensed />
        <div class="text-blue font-light">
          <p class="mb-4">
            If the supply air passes through a first heater of some form before
            arriving at the first cooling coil, then it may be preferable to
            turn down, turn off or bypass the first heater so the cooling
            benefit for the cooling system can be optimised. This applies if
            there is a net saving in energy consumption by prioritising the
            cooling benefit for the cooling system. This is particularly likely
            if operation of the cooler in the cooling system can be eliminated
            through deployment of Symphony Cycle. Apart from the net energy
            savings benefit, there is also a likely maintenance and lifecycle
            cost benefit to reducing the operating hours on the cooler(s).
          </p>
          <p class="mb-4">
            If the first heater is a heat recovery unit, then it may be possible
            to manage the amount of heat transferred into the supply air (or
            first air flow) by varying the speed of a rotary wheel or by varying
            the passage or air through a bypass damper.
          </p>
          <p class="mb-4">
            With or without Symphony Cycle, any vulnerable equipment such as
            heating or cooling coils in the air handling units need to be
            protected from frost.
          </p>
        </div>
      </section-wrapper>
      <section-wrapper>
        <section-header title="Implementation" condensed />
        <div class="text-blue font-light">
          <p class="mb-4">
            Symphony Cycle can be implemented through programming applied to a
            BMS (Building Management System)/BAS (Building Automation System)
            using the principles noted above.
          </p>
          <p class="mb-4">
            To maximise the Symphony Cycle heat transfer capacity, consideration
            may also be given to channelling the higher temperature cooling
            water returning from other cooling circuits directly into the
            cooling water supply to the air handling unit cooling coils. This
            can be done using a piped interconnector and motorised valves. The
            valves can be programmed to switch between normal cooling mode and
            Symphony Cycle mode. Normal cooling mode would be as though there
            was no interconnecting pipe. Symphony mode would divert the return
            water from the cooling system circuit(s) that are not connected to
            the circuit supplying the first ventilation system (typically the
            air handling units that draws in outdoor air), to the circuit
            supplying the first ventilation system.
          </p>
        </div>
      </section-wrapper>
      <section class="mt-8">
        <header class="mb-6">
          <h2 class="text-2xl text-blue font-medium mb-4">
            Frequently Asked Questions
          </h2>
        </header>
        <div class="faq-grid grid gap-2">
          <faq-item
            title="What is the outdoor temperature threshold below which Symphony Cycle can operate effectively?"
          >
            <p>
              This varies from building to building depending on its
              architecture, location, usage and space or process requirements.
              In general, one can expect it will work up to an outside
              temperature of 15°C however many buildings may continue to benefit
              from using Symphony Cycle up to a limit of about 18°C. Some
              applications may be able to go further than this. The higher
              temperatures are more likely achievable with deployment of the
              interconnector and motorised valves noted under ‘Implementation’
              and/or if space or process temperatures can be elevated. Careful
              fine tuning of the BMS/BAS programming helps achieve maximum
              results.
            </p>
          </faq-item>
          <faq-item
            title="What skillset or type of professional is needed to implement Symphony Cycle?"
          >
            <p>
              Symphony Cycle is implemented by adding control routines to an
              existing BMS (Building Management System)/BAS (Building Automation
              System) that controls the building’s central HVAC (Heating,
              Ventilation & Air-Conditioning) plant. It therefore needs to be
              implemented by somebody competent in programming the building’s
              BMS/BAS such as a controls contractor or building maintenance
              contractor.
            </p>
          </faq-item>
          <faq-item
            title="How long should it take someone to program Symphony Cycle into my building?"
          >
            <p>
              It should be possible to program most small to medium sized
              commercial buildings within a day where the HVAC system involves a
              single cooling system and up to three main Air Handlers. It may
              take the programmer a second day if he has not implemented
              Symphony Cycle before. Some programming systems are less flexible
              than others and thereby can incur further programming time.
            </p>
          </faq-item>
          <faq-item
            title="Is it necessary to fine tune Symphony Cycle or have it maintained after it has been implemented?"
          >
            <p>
              It is possible to implement a program to operate Symphony Cycle
              without the need to revisit it in the future. Nevertheless, it is
              likely worthwhile to monitor its performance across the non-summer
              periods to check that its capability is being utilised. Assuming
              the principles of operation are programmed correctly, then one
              might fine tune the thresholds by which Symphony Cycle is
              activated and how other plant performance thresholds are set in
              order to maximise the energy savings benefits of both.
            </p>
          </faq-item>
          <faq-item
            title="Does the piped interconnector and associated motorised valves need to be installed?"
          >
            <p>
              Symphony Cycle can operate without the piped interconnector and
              associated motorised valves. It is likely however that a higher
              level of energy savings performance will be possible if they are
              installed.
            </p>
          </faq-item>
          <faq-item
            title="Will heat recovery before the cooling coil limit the savings from Symphony Cycle?"
          >
            <p>
              The colder the outside air, the cooler the air temperature in the
              Air Handler before it meets the cooling coil - with or without
              heat recovery before the cooling coil. With heat recovery before
              the cooling coil, it should typically be possible to extract the
              full capacity of Symphony Cycle for outdoor air temperatures below
              10degC. This largely depends on the size of the cooling load in
              the building and how it varies with outside temperature. As the
              outdoor temperature rises, one can substitute some or all of the
              heat recovery before the cooling coil for heat passed by Symphony
              Cycle into the Air Handler from the cooling coil. The benefit of
              doing the latter is to maintain a supply of free cooling water for
              the purpose of keeping the cooler/chiller off or limiting its
              workload. This is typically achievable without incurring any cost
              to the supply of heat into the Air Handler.
            </p>
          </faq-item>
          <faq-item
            title="When should Symphony Cycle be activated in a building?"
          >
            <p>
              Symphony Cycle should be active when the building needs cooling
              and the outside air temperature is suitable for its operation.
            </p>
          </faq-item>
          <faq-item
            title="Can Symphony Cycle operate when the cooler/chiller is also operating?"
          >
            <p>
              Yes — in this scenario Symphony Cycle needs to be substituting
              some of the load that the chiller would otherwise be serving.
              Symphony Cycle can do this so long as the cooler/chiller’s
              operating temperature is sufficiently high so that the cooling
              coil is still able to offload heat into the Air Handler.
            </p>
          </faq-item>
          <faq-item
            title="When can the Cooler/Chiller be switched off and Symphony Cycle serve the building’s cooling load on its own?"
          >
            <p>
              When conditions are suitable for Symphony Cycle, it should be
              activated for a period before considering activation of the
              Cooler/Chiller. The Cooler/Chiller can remain off if Symphony
              Cycle proves capable of supporting the full cooling load of the
              building.
            </p>
          </faq-item>
          <faq-item
            title="Do the Cooler/Chiller’s primary pumps need to be in operation when Symphony Cycle is active and fully supporting the building’s cooling load?"
          >
            <p>
              Typically not but this may depend in particular on the location of
              non-return valves in the cooling system.
            </p>
          </faq-item>
          <faq-item
            title="Does a Cooling System’s Condenser pumps and/or cooling towers need to be in operation if Symphony Cycle is active and fully supporting the building’s cooling load?"
          >
            <p>No.</p>
          </faq-item>
          <faq-item
            title="When can the building’s heating system be turned off with Symphony Cycle alone fulfilling its heating needs?"
          >
            <p>
              If the circuits supporting the building's other heating loads are
              not in demand and if Symphony Cycle is providing sufficient heat
              into the Air Handler, then the heating circuit to the Air
              Handler’s heating coil can also be switched off. With all the
              heating circuits switched off, the primary circulation pumps and
              the heater/Boiler(s) can be switched off. The circuit supporting
              the Air Handler heating coils is often the last circuit that needs
              heat when the outside temperature rises. Under such circumstances,
              Symphony Cycle can allow the heating system to switch off entirely
              and sooner than otherwise, thereby saving on heater/boiler
              operation, primary pumping and pumping to the Air Handler heating
              coils.
            </p>
          </faq-item>
        </div>
      </section>
    </div>
    <div v-else>
      <denied-access />

      <!-- </section-wrapper-dark> -->
    </div>
  </div>
</template>

<script>
import SectionWrapper from "./Layout/SectionWrapper";
import DeniedAccess from "./Layout/DeniedAccess";
import { mapState, mapActions } from "vuex";
import SectionHeader from "./Layout/SectionHeader";
import FaqItem from "./FaqItem";

export default {
  name: "SupportHome",
  components: {
    SectionWrapper,
    DeniedAccess,
    SectionHeader,
    FaqItem,
  },
  computed: {
    isAnyLicenseActive() {
      let licenses = this.licenses;

      licenses = licenses.filter((license) => license.status === "active");

      if (licenses.length > 0) {
        return true;
      }
      return false;
    },
    ...mapState(["licenses", "user"]),
  },
  methods: {
    ...mapActions(["getLicenses"]),
  },
  mounted() {
    if (!this.licenses) {
      this.loading = true;
      this.getLicenses().then((response) => {
        console.log("here", response);
        this.loading = false;
      });
    }
  },
};
</script>
