<template>
  <div
    class="
      login
      flex flex-col
      items-center
      justify-center
      min-h-screen
      text-gray-900
    "
    v-if="!loading"
  >
    <img src="@/assets/symphony-logo.svg" alt="Symphony" />
    <div
      class="login-box w-full max-w-xs rounded-2xl bg-white shadow p-6 mt-14"
    >
      <h1 class="text-2xl font-medium">Forgot Password</h1>
      <form @submit.prevent="forgotPassword">
        <div
          v-if="error"
          class="
            error
            bg-red-light
            text-center
            rounded
            p-4
            mt-2
            leading-4
            text-red
          "
        >
          {{ error }}
        </div>
        <div
          v-else-if="success"
          class="
            text-center
            rounded
            p-4
            mt-2
            leading-4
            text-green
          "
        >
          {{ success }}
        </div>
        <div class="form-input mt-4">
          <input-field
            v-model="email"
            label="Email"
            name="email"
            type="text"
            :required="true"
          />
        </div>
        <div class="form-input mt-8">
          <button
            type="submit"
            class="
              w-full
              rounded-md
              bg-green
              py-3
              px-6
              text-white
              hover:opacity-90
              disabled:opacity-50 disabled:cursor-not-allowed
            "
            :disabled="v$.$invalid"
          >
            Get Code
          </button>
        </div>
      </form>
    </div>
  </div>
  <div
    v-if="loading"
    class="flex flex-col items-center justify-center min-h-screen w-full"
  >
    <loading title="Loading..." />
  </div>
</template>

<script>
import Loading from "@/components/Layout/Loading";
import InputField from "@/components/Library/Input";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import api from "@/modules/api";

export default {
  name: "ForgotPassword",
  components: {
    Loading,
    InputField,
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  data: () => ({
    email: "",
    password: "",
    loading: false,
    error: null,
    success: null,
  }),
  validations() {
    return {
      email: {
        required,
        email,
      },
    };
  },
  computed: {},
  methods: {
    checkAuth() {
      if (this.user) {
        this.$router.push("/");
      }
    },
    forgotPassword(e) {
      e.preventDefault();
      this.loading = true;
      this.error = false;
      this.getCode({
        email: this.email,
      })
        .then(() => {
          this.loading = false;
          this.success = "An email has been sent to your inbox";
          // this.$router.push('/');
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.error = error.message;
          } else this.error = "Opps there was an error please try later";
        });
    },
    getCode(email) {
      return new Promise((resolve, reject) => {
        api({
          method: "post",
          url: "/customer/getCode",
          data: email,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response.data);
            }
          });
      });
    },
  },
};
</script>