<template>
  <symbol
    id="icon-implementation"
    viewBox="0 0 18 18"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 2L9 4V2.565C6.95778 2.565 5.13776 3.51633 3.95892 5H2.5778C3.91393 2.8593 6.29064 1.435 9 1.435V0L11 2ZM13 3.95892V2.5778C15.1407 3.91393 16.565 6.29064 16.565 9H18L16 11L14 9H15.435C15.435 6.95778 14.4837 5.13776 13 3.95892ZM14.0411 13C12.8622 14.4837 11.0422 15.435 9 15.435V14L7 16L9 18V16.565C11.7094 16.565 14.0861 15.1407 15.4222 13H14.0411ZM5 15.4222V14.0411C3.51633 12.8622 2.565 11.0422 2.565 9H4L2 7L0 9H1.435C1.435 11.7094 2.8593 14.0861 5 15.4222Z" fill="currentColor"/>
  </symbol>
</template>

<script>
export default {
  name: 'IconImplementation'
}
</script>