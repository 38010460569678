<template>
  <symbol
    id="icon-support"
    viewBox="0 0 18 18"
  >
    <path d="M6.75 15.75H11.25" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M3.95153 7.31258C3.9506 6.64572 4.0816 5.98527 4.33701 5.36925C4.59241 4.75324 4.96716 4.19385 5.43968 3.72329C5.9122 3.25273 6.47315 2.8803 7.09022 2.62747C7.70729 2.37463 8.36828 2.24637 9.03513 2.25008C11.8195 2.27117 14.0484 4.58445 14.0484 7.37586V7.87508C14.0484 10.3923 14.5758 11.8548 15.0398 12.6563C15.0891 12.7417 15.1151 12.8385 15.1152 12.9371C15.1153 13.0357 15.0894 13.1325 15.0403 13.218C14.9912 13.3034 14.9204 13.3745 14.8352 13.424C14.75 13.4735 14.6532 13.4997 14.5547 13.5001H3.44528C3.34671 13.4997 3.24996 13.4735 3.16473 13.424C3.07949 13.3745 3.00877 13.3034 2.95963 13.218C2.9105 13.1325 2.88468 13.0357 2.88477 12.9371C2.88485 12.8385 2.91084 12.7417 2.96013 12.6563C3.42419 11.8548 3.95153 10.3923 3.95153 7.87508V7.31258Z" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
  </symbol>
</template>

<script>
export default {
  name: 'IconSupport'
}
</script>