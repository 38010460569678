<template>
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <icon-account />
    <icon-billing />
    <icon-building />
    <icon-check />
    <icon-chevron-down />
    <icon-close />
    <icon-energy />
    <icon-edit />
    <icon-home />
    <icon-implementation />
    <icon-location />
    <icon-notifications />
    <icon-support />
    <icon-license />
  </svg>
</template>

<script>
import IconAccount from '@/components/Icons/IconAccount'
import IconBilling from '@/components/Icons/IconBilling'
import IconBuilding from '@/components/Icons/IconBuilding'
import IconCheck from '@/components/Icons/IconCheck'
import IconChevronDown from '@/components/Icons/IconChevronDown'
import IconClose from '@/components/Icons/IconClose'
import IconEnergy from '@/components/Icons/IconEnergy'
import IconEdit from '@/components/Icons/IconEdit'
import IconHome from '@/components/Icons/IconHome'
import IconImplementation from '@/components/Icons/IconImplementation'
import IconLocation from '@/components/Icons/IconLocation'
import IconNotifications from '@/components/Icons/IconNotifications'
import IconSupport from '@/components/Icons/IconSupport'
import IconLicense from '@/components/Icons/IconLicense'

export default {
  name: 'IconLibrary',
  components: {
    IconAccount,
    IconBilling,
    IconBuilding,
    IconCheck,
    IconChevronDown,
    IconClose,
    IconEnergy,
    IconEdit,
    IconHome,
    IconImplementation,
    IconLocation,
    IconNotifications,
    IconSupport,
    IconLicense
  },
}
</script>