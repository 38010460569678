<template>
  <loading v-if="loading" title="Loading buildings..."></loading>
  <div v-if="!loading">
    <header class="mb-6">
      <h1 class="text-4xl text-blue font-medium mb-4">Building data</h1>

      <div class="container mx-auto flex items-center justify-between">
        <div class="">
          <p class="font-light text-blue basis-3/4">
            Manage your building data.
          </p>
        </div>
        <div class="">
          <button
            class="
              py-3
              px-4
              ml-2
              text-sm
              leading-3
              rounded-md
              shadow
              basis-1/4
              bg-dark-grey
              text-white
              whitespace-nowrap
              disabled:opacity-50 disabled:button-not-allowed
            "
            :disabled="!isValid"
            @click="openCreatingModal"
          >
            {{ "Create Building" }}
          </button>
        </div>
      </div>
    </header>
    <div class="buildings-list grid gap-3">
      <template v-for="building in sortedBuildings" :key="building.id">
        <building-item
          :building="building"
          @edit="editBuilding"
        ></building-item>
      </template>
    </div>
  </div>
  <modal-wrapper
    title="Edit Building"
    v-if="(editing && currentBuilding) || creating"
    @close="closeEditBuilding"
  >
    <template #content>
      <building-edit v-if="editing" />
      <building-create v-else-if="creating" />
    </template>
    <template #actions>
      <button
        class="
          py-3
          px-4
          ml-2
          text-sm
          leading-3
          rounded-md
          shadow
          border border-red
          bg-red
          text-white
          whitespace-nowrap
          disabled:opacity-50 disabled:button-not-allowed
        "
        :disabled="!isValid"
        v-if="editing"
        @click="deleteCurrentBuilding"
      >
        {{ deleting ? "Deleting..." : "Delete Building" }}
      </button>
      <button
        class="
          py-3
          px-4
          ml-2
          text-sm
          leading-3
          rounded-md
          shadow
          border border-green
          bg-green
          text-white
          whitespace-nowrap
          disabled:opacity-50 disabled:button-not-allowed
        "
        :disabled="!getEditingIsValid"
        v-if="editing"
        @click="updateCurrentBuilding"
      >
        {{ saving ? "Saving..." : "Update Building" }}
      </button>
      <button
        class="
          py-3
          px-4
          ml-2
          text-sm
          leading-3
          rounded-md
          shadow
          border border-green
          bg-green
          text-white
          whitespace-nowrap
          disabled:opacity-50 disabled:button-not-allowed
        "
        v-if="creating"
        :disabled="!getCreatingIsValid"
        @click="createNewBuilding"
      >
        {{ saving ? "Saving..." : "Create Building" }}
      </button>
    </template>
  </modal-wrapper>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import Loading from "@/components/Layout/Loading";
import BuildingItem from "@/components/Buildings/BuildingItem";
import BuildingEdit from "@/components/Buildings/BuildingEdit";
import BuildingCreate from "@/components/Buildings/BuildingCreate";
import ModalWrapper from "@/components/Layout/ModalWrapper";

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export default {
  name: "BuildingsList",
  components: {
    Loading,
    BuildingItem,
    BuildingEdit,
    BuildingCreate,
    ModalWrapper,
  },
  data: () => ({
    loading: false,
    editing: false,
    creating: false,
    saving: false,
    deleting: false,
    isValid: true,
  }),
  mounted() {
    this.getBuildings().then(() => {
      this.getLicenses();
      this.loading = false;
    });
    // if (!this.buildings.length) {
    //   this.loading = true;
    // }
  },
  computed: {
    sortedBuildings() {
      if (this.buildingsWithLicenses.length) {
        return this.buildingsWithLicenses.slice().sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      }

      return this.buildings;
    },
    ...mapState(["buildings", "currentBuilding"]),
    ...mapGetters([
      "buildingsWithLicenses",
      "getEditingIsValid",
      "getCreatingIsValid",
    ]),
  },
  methods: {
    editBuilding(id) {
      this.editing = true;
      this.setCurrentBuilding({
        ...this.buildings.find((building) => building.id === id),
      });
    },
    closeEditBuilding() {
      this.editing = false;
      this.creating = false;
      this.setCurrentBuilding(null);
    },
    updateCurrentBuilding() {
      this.saving = true;
      this.updateBuilding(this.currentBuilding)
        .then(() => {
          this.getBuildings().then(() => {
            this.getLicenses();
          });
          this.saving = false;
          this.closeEditBuilding();
        })
        .catch((error) => {
          console.log(error);
          this.saving = false;
          this.closeEditBuilding();
        });
    },
    deleteCurrentBuilding() {
      this.deleting = true;
      this.deleteBuilding(this.currentBuilding)
        .then(() => {
          this.getBuildings().then(() => {
            this.getLicenses();
          });
          this.deleting = false;
          this.closeEditBuilding();
        })
        .catch((error) => {
          console.log(error);
          this.deleting = false;
          this.closeEditBuilding();
        });
    },
    openCreatingModal() {
      this.creating = true;
    },

    createNewBuilding() {
      this.saving = true;
      this.setNewBuildingProp({ prop: "uid", value: uuidv4() });
      this.createBuilding(this.newBuilding).then(() => {
        this.clearNewBuilding();
        this.getBuildings().then(() => {
          this.getLicenses();
        });
        this.saving = false;
        this.closeEditBuilding();
      });
    },

    ...mapActions([
      "getBuildings",
      "getLicenses",
      "updateBuilding",
      "deleteBuilding",
      "createBuilding",
    ]),
    ...mapMutations([
      "setCurrentBuilding",
      "setNewBuildingProp",
      "clearNewBuilding",
    ]),
  },
};
</script>

<style scoped>
/* * {
  border: 1px solid black;
} */
</style>