<template>
  <div class="flex w-full justify-start border-b-2 border-light-grey">
    <button 
      v-for="tab in tabs" 
      :key="`${uid}-${tab.value}`"
      class="py-2 px-4 border-b-2 bg-white -mb-0.5 hover:text-blue"
      :class="{ 'text-secondary border-light-grey': tab.value !== modelValue, 'text-blue border-blue': tab.value === modelValue }"
      @click="select(tab.value)"
    >{{ tab.label }}</button>
  </div>
</template>

<script>
import { uid } from 'uid'

export default {
  name: "TabsComponent",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  computed: {
    uid () {
      return uid()
    },
  },
  methods: {
    select(value) {
      this.$emit('update:modelValue', value)
    },
  },
}
</script>