<template>
  <div
    class="
      flex
      items-center
      py-6
      pl-3
      pr-5
      rounded-xl
      bg-white
      text-blue
      shadow
    "
  >
    <div
      class="
        building-icon
        w-14
        h-14
        flex
        items-center
        justify-center
        bg-light
        border border-light-grey
        rounded-lg
      "
    >
      <icon icon="location" size="8" />
    </div>
    <div class="building-details ml-6">
      <h3 class="font-medium mb-1">
        {{ building.name }}
      </h3>
      <p class="text-sm font-light mb-0">
        {{ fullAddress }}
      </p>
      <p
        class="text-sm font-light"
        v-if="license && license.status == 'active'"
      >
        {{ license.id }} | Renews
        <span class="font-medium">{{ renewalDate }}</span>
      </p>
      <p class="text-sm font-light" v-else-if="license">
        {{ license.id }} | Pending
      </p>
    </div>
    <div class="ml-auto mr-6"></div>
    <button
      class="
        py-2
        px-4
        flex
        items-center
        ml-auto
        text-sm
        leading-3
        rounded-md
        border border-light-grey
        bg-light
        whitespace-nowrap
      "
      v-if="license && license.status != 'active' || user.isAdmin"
      @click="$emit('edit', building.id)"
    >
      <icon icon="edit" size="4" />
      <span> Edit </span>
    </button>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import { format, parseISO } from "date-fns";
import { mapState, mapGetters } from "vuex";

export default {
  name: "BuildingItem",
  components: {
    Icon,
  },
  props: {
    building: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getLicensesArray"]),
    ...mapState(["user"]),
    fullAddress() {
      return `${this.building.address1} ${
        this.building.address2 ? "," + this.building.address2 : ""
      }, ${this.building.zip || ""} - ${this.building.city || ""}, ${
        this.building.country
      }`;
    },
    renewalDate() {
      if (this.license.expires_at && this.license.expires_at != "-") {
        return format(parseISO(this.license.expires_at), "dd MMMM yyyy");
      }

      return null;
    },
    licenses() {
      return this.getLicensesArray;
    },
    license() {
      let license;
      if (this.licenses && this.building.licenses) {
        license = this.getLicensesArray.filter(
          (license) => license.id == this.building.licenses.id
        );
      }
      return license ? license[0] : false;
    },
  },
  mounted() {
  },
};
</script>