<template>
  <symbol
    id="icon-notifications"
    viewBox="0 0 18 18"
  >
    <path d="M15.8555 9.00024H13.6055C13.3071 9.00024 13.021 9.11876 12.81 9.32974C12.599 9.54072 12.4805 9.82687 12.4805 10.1252V12.9377C12.4805 13.2361 12.599 13.5223 12.81 13.7332C13.021 13.9442 13.3071 14.0627 13.6055 14.0627H14.7305C15.0288 14.0627 15.315 13.9442 15.526 13.7332C15.7369 13.5223 15.8555 13.2361 15.8555 12.9377V9.00024ZM15.8555 9.00024C15.8555 8.10906 15.6791 7.2267 15.3363 6.40406C14.9936 5.58143 14.4914 4.8348 13.8586 4.20727C13.2258 3.57973 12.4751 3.08371 11.6496 2.74781C10.8242 2.41192 9.94036 2.24281 9.04922 2.25023C8.15866 2.24374 7.27563 2.41354 6.45099 2.74985C5.62635 3.08616 4.87642 3.58233 4.2444 4.20978C3.61238 4.83722 3.11077 5.58353 2.76848 6.4057C2.42619 7.22787 2.24998 8.10966 2.25 9.00024V12.9377C2.25 13.2361 2.36853 13.5223 2.5795 13.7332C2.79048 13.9442 3.07663 14.0627 3.375 14.0627H4.5C4.79837 14.0627 5.08452 13.9442 5.29549 13.7332C5.50647 13.5223 5.625 13.2361 5.625 12.9377V10.1252C5.625 9.82687 5.50647 9.54072 5.29549 9.32974C5.08452 9.11876 4.79837 9.00024 4.5 9.00024H2.25" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M15.8555 12.9375V14.625C15.8555 15.2217 15.6184 15.794 15.1965 16.216C14.7745 16.6379 14.2022 16.875 13.6055 16.875H9.5625" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
  </symbol>
</template>

<script>
export default {
  name: 'IconNotifications'
}
</script>