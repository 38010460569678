import api from "@/modules/api";
import router from "@/router";

export default {
  loginUser({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      api({
        method: "post",
        url: "/customer/login",
        data: credentials,
        redirect: 'follow'
      })
        .then(response => {
          localStorage.setItem("sc_token", response.data.customer.accessToken);
          localStorage.setItem("sc_uid", response.data.customer.id);
          commit('setUser', response.data.customer);
          commit('setToken', response.data.customer.accessToken);
          commit('setUid', response.data.customer.id);
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          }
        })
    })
  },
  // forgotPassword({undefined}, credentials) {
  //   return new Promise((resolve, reject) => {
  //     api({
  //       method: "get",
  //       url: "/customer/getCod",
  //       data: credentials,
  //       redirect: 'follow'
  //     })
  //       .then(response => {
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error.response.data);
  //       })
  //   })
  // },
  logoutUser({ commit }) {
    localStorage.removeItem("sc_token");
    localStorage.removeItem("sc_uid");
    commit('setUser', null);
    commit('setToken', null);
    commit('setUid', null);
    commit('cleanAll', null);
    router.push({ name: "Login" })
  },

  getUser({ state, commit }) {
    return new Promise((resolve, reject) => {
      const token = state.token;
      const uid = state.uid;

      if (!token) {
        reject("No token found");
      }

      api({
        method: 'get',
        url: `/customer/${uid}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          commit('setUser', response.data.customer);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    })
  },

  getAllUsers({ state, commit }) {
    return new Promise((resolve, reject) => {
      const token = state.token;

      if (!token) {
        reject("No token found");
      }

      api({
        method: 'get',
        url: `/customer`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          // commit('setUsers', response.data.customer.filter(user => !user.isAdmin));
          commit('setUsers', response.data.customer);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    })
  },

  updateUser({ state, commit }) {
    return new Promise((resolve, reject) => {
      const token = state.token;
      const uid = state.uid;

      if (!token) {
        reject("No token found");
      }

      api({
        method: 'post',
        data: state.user,
        url: `/customer/${uid}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          commit('setUser', response.data.customer);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    })
  },
  updatePassword({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      const token = state.token;

      if (!token) {
        reject("No token found");
      }

      api({
        method: 'post',
        data: payload,
        url: `/customer/password`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          commit('setUser', response.data.customer);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    })
  },

  getBuildings({ state, commit }) {
    return new Promise((resolve, reject) => {
      const token = state.token;

      if (!token) {
        reject("No token found");
      }

      api({
        method: "get",
        url: "/building",
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          // Return only one building with the same id - remove duplicates.
          const buildingsUnique = response.data.building.reduce((acc, building) => {
            const existingBuilding = acc.find(b => b.id === building.id);
            if (!existingBuilding) {
              acc.push(building);
            }
            return acc;
          }, []);
          commit('setBuildings', buildingsUnique);
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateBuilding({ state, commit }) {
    return new Promise((resolve, reject) => {
      const token = state.token;
      const building = state.currentBuilding;

      if (!token) {
        reject("No token found");
      }

      api({
        method: "post",
        data: building,
        url: `/building/${building.id}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          commit('refreshBuilding', response.data.building);
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteBuilding({ state }) {
    return new Promise((resolve, reject) => {
      const token = state.token;
      const building = state.currentBuilding;

      if (!token) {
        reject("No token found");
      }

      api({
        method: "delete",
        data: building,
        url: `/building/${building.id}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          // commit('efreshBuilding', response.data);
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createBuilding({ state }) {
    return new Promise((resolve, reject) => {
      const token = state.token;
      const building = state.newBuilding;

      if (!token) {
        reject("No token found");
      }

      api({
        method: "post",
        data: [{ ...building }],
        url: `/building/createwl`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          console.log(response);
          resolve(response)
        })
        .catch(error => {
          console.log(error);
          reject(error)
        })
    })
  },

  getLicenses({ state, commit }) {
    return new Promise((resolve, reject) => {
      const token = state.token;

      if (!token) {
        reject("No token found");
      }

      api({
        method: "get",
        url: "/license",
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          commit('setLicenses', response.data.licenses);
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateLicense({ state, commit }) {
    return new Promise((resolve, reject) => {
      const token = state.token;
      const license = state.currentLicense;

      if (!token) {
        reject("No token found");
      }

      // If newStartDate is different than currentLicense.created_at, update the license.
      if (state.newStartDate !== license.created_at) {
        commit('setLicenseProp', {
          prop: 'created_at',
          value: state.newStartDate
        })
      }

      // If newEndDate is different than currentLicense.expires_at, update the license.
      if (state.newEndDate !== license.expires_at) {
        commit('setLicenseProp', {
          prop: 'expires_at',
          value: state.newEndDate
        })
      }

      api({
        method: "post",
        data: license,
        url: `/license/${license.id}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          console.log(response);
          commit('refreshLicense', license);
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}