<template>
  <div
    class="
      login
      flex flex-col
      items-center
      justify-center
      min-h-screen
      text-gray-900
    "
    v-if="!loading"
  >
    <img src="@/assets/symphony-logo.svg" alt="Symphony" />
    <div
      class="login-box w-full max-w-xs rounded-2xl bg-white shadow p-6 mt-14"
    >
      <h1 class="text-2xl font-medium">Forgot Password</h1>
      <form @submit.prevent="changePassword">
        <div
          v-if="error"
          class="
            error
            bg-red-light
            text-center
            rounded
            p-4
            mt-2
            leading-4
            text-red
          "
        >
          {{ error }}
        </div>
        <div
          v-else-if="success"
          class="text-center rounded p-4 mt-2 leading-4 text-green"
        >
          {{ success }}
        </div>
        <div class="form-input mt-4">
          <input-field v-model="code" label="Code" :required="true" type="text">
            <template #error v-if="this.v$.code.$invalid"
              >{{ v$.code.$silentErrors[0].$message }}
            </template>
          </input-field>
        </div>
        <div class="form-input mt-4">
          <input-field
            v-model="newPassword"
            label="Password"
            :required="true"
            type="password"
          >
            <template #error v-if="this.v$.newPassword.$invalid"
              >{{ v$.newPassword.$silentErrors[0].$message }}
            </template>
          </input-field>
        </div>
        <div class="form-input mt-4">
          <input-field
            v-model="confirmPassword"
            label="Confirm Password"
            :required="true"
            type="password"
          >
            <template #error v-if="this.v$.confirmPassword.$invalid"
              >{{ v$.confirmPassword.$silentErrors[0].$message }}
            </template>
          </input-field>
        </div>
        <div class="form-input mt-8">
          <button
            type="submit"
            class="
              w-full
              rounded-md
              bg-green
              py-3
              px-6
              text-white
              hover:opacity-90
              disabled:opacity-50 disabled:cursor-not-allowed
            "
            :disabled="v$.$invalid"
          >
            Change Password
          </button>
        </div>
      </form>
    </div>
  </div>
  <div
    v-if="loading"
    class="flex flex-col items-center justify-center min-h-screen w-full"
  >
    <loading title="Loading..." />
  </div>
</template>

<script>
import Loading from "@/components/Layout/Loading";
import InputField from "@/components/Library/Input";
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs, helpers } from "@vuelidate/validators";
import api from "@/modules/api";

export default {
  name: "ChangePassword",
  components: {
    Loading,
    InputField,
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  data: () => ({
    email: "",
    code: "",
    newPassword: "",
    confirmPassword: "",
    loading: false,
    error: null,
    success: null,
  }),
  validations() {
    return {
      code: {
        required,
      },
      newPassword: {
        required: helpers.withMessage("This field cannot be empty", required),
        minLength: minLength(6),
      },
      confirmPassword: {
        required: helpers.withMessage("This field cannot be empty", required),
        sameAsPassword: sameAs(this.newPassword),
      },
    };
  },
  computed: {},
  methods: {
    checkAuth() {
      if (this.user) {
        this.$router.push("/");
      }
    },
    changePassword(e) {
      e.preventDefault();
      this.loading = true;
      this.error = false;
      this.changePasswordRequest({
        code: this.code,
        new_password: this.newPassword,
      })
        .then(() => {
          this.loading = false;
          this.success = "Password Changed Succesfully"
          setTimeout( () => this.$router.push({ path: '/login'}), 2000);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error) {
            this.error = error.message;
          } else this.error = "Opps there was an error please try later";
        });
    },
    changePasswordRequest(newPassObj) {
      return new Promise((resolve, reject) => {
        api({
          method: "put",
          url: "/customer/setPassword",
          data: newPassObj,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (error.response) {
              reject(error.response.data);
            }
          });
      });
    },
  },
  mounted() {
    this.code = this.$route.query.code || "";
  },
};
</script>