<template>
  <div class="flex items-center">
    <span class="text-secondary">Account</span>
    <span class="separator mx-1">/</span>
    <span>{{ currentRoute }}</span>
  </div>
</template>

<script>
export default {
  name: "BreadCrumbs",
  computed: {
    currentRoute () {
      return this.$route.name
    },
  }
}
</script>