<template>
  <div class="login flex flex-col items-center justify-center min-h-screen text-gray-900" v-if="!loading">
    <img src="@/assets/symphony-logo.svg" alt="Symphony">
    <div class="login-box w-full max-w-xs rounded-2xl bg-white shadow p-6 mt-14">
      <h1 class="text-2xl font-medium">Login</h1>
      <form @submit.prevent="login">
        <div v-if="error" class="error bg-red-light text-center rounded p-4 mt-2 leading-4 text-red">{{ error }}</div>
        <div class="form-input mt-4">
          <input-field
            v-model="email"
            label="Email" 
            :required="true"
          />
        </div>
        <div class="form-input mt-4">
          <input-field
            v-model="password"
            label="Password" 
            :required="true"
            type="password" 
          />
        </div>
        <div class="w-full justify-center mt-2 opacity-50 flex hover:opacity-90 disabled:opacity-50" @click="goToForgotPassword">
          <a href="">
          Forgot Password
          </a>
        </div>
        <div class="form-input mt-5">
          <button 
            type="submit" 
            class="w-full rounded-md bg-green py-3 px-6 text-white hover:opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
            :disabled="v$.$invalid"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  </div>
  <div v-if="loading" class="flex flex-col items-center justify-center min-h-screen w-full">
    <loading title="Loading..." />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from '@/components/Layout/Loading'
import InputField from '@/components/Library/Input'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
  name: "UserLogin",
  components: {
    Loading,
    InputField
  },
  setup: () => ({ 
    v$: useVuelidate() 
  }),
  data: () => ({
    email: '',
    password: '',
    loading: false,
    error: null
  }),
  validations () {
    return {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  computed: {
    ...mapState(['user', 'uid'])
  },
  methods: {
    checkAuth () {
      if (this.user) {
        this.$router.push('/')
      }
    },
    goToForgotPassword () {
      this.$router.push('/forgotpassword')
    },
    login(e) {
      e.preventDefault()
      this.loading = true;
      this.error = false;
      this.loginUser({
        email: this.email,
        password: this.password
      })
      .then(() => {
        this.loading = false;
        this.$router.push('/');
      })
      .catch(error => {
        this.loading = false;
        this.error = error.message;
      })
    },
    ...mapActions(['loginUser']),
  }
}
</script>