<template>
  <symbol
    id="icon-check"
    viewBox="0 0 18 18"
  >
    <path d="M6 8.74751L8.42347 12L12 6" stroke="currentColor" fill="transparent" stroke-width="2" stroke-linecap="round"/>
  </symbol>
</template>

<script>
export default {
  name: 'IconCheck'
}
</script>