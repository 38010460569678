export default {
  buildingsWithLicenses: state => {
    if (state.buildings.length === 0) {
      return []
    }

    if (state.licenses.length === 0) {
      return state.buildings
    }

    return state.buildings.map(building => {
      
      if (!building.licenses) {
        return building
      }
      const license = state.licenses.find(l => l.building.id == building.id)
      if (license) {
        return {
          ...building,
          licenses: {
            id: license.id,
            status: license.status,
            expires_at: license.expires_at,
          },
          // ...license,
        }
      }
      return building

    })
  },

  getEditingIsValid: state => {
    return state.editingIsValid
  },
  getLicensesArray: state => {
    return state.licenses
  },
  getCreatingIsValid: state => {
    return state.creatingIsValid
  }
}