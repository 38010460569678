<template>
  <symbol
    id="icon-chevron-down"
    viewBox="0 0 18 18"
  >
    <path d="M14.625 6.75L9 12.375L3.375 6.75" stroke="currentColor" fill="transparent" stroke-width="1.4375" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>
</template>

<script>
export default {
  name: 'IconChevronDown'
}
</script>