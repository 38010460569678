<template>
  <nav class="symphony-cycle-nav flex flex-col">
    <router-link 
      to="/"
      class="p-2 rounded hover:text-blue flex items-center" 
      :class="activeRoute === '/' ? 'text-blue': 'text-secondary'"
    > 
      <icon icon="building" size="5" />
      <span class="nav-label ml-2">Building Data</span>
    </router-link>
    <router-link 
      to="/account"
      class="p-2 rounded hover:text-blue flex items-center" 
      :class="activeRoute === '/account' ? 'text-blue': 'text-secondary'"
    > 
      <icon icon="account" size="5" />
      <span class="nav-label ml-2">Your Details</span>
    </router-link>
    <router-link 
      to="/billing"
      class="p-2 rounded hover:text-blue flex items-center" 
      :class="activeRoute === '/billing' ? 'text-blue': 'text-secondary'"
    >
      <icon icon="billing" size="5" />
      <span class="nav-label ml-2">Billing</span>
    </router-link>
    <router-link 
      to="/implementation"
      class="p-2 rounded hover:text-blue flex items-center" 
      :class="activeRoute === '/implementation' ? 'text-blue': 'text-secondary'"
    > 
      <icon icon="implementation" size="5" />
      <span class="nav-label ml-2">Implementation</span>
    </router-link>
    <!-- <router-link 
      to="/managebuildings"
      v-if="user.isAdmin"
      class="p-2 rounded hover:text-blue flex items-center" 
      :class="activeRoute === '/managebuildings' ? 'text-blue': 'text-secondary'"
    > 
      <icon icon="building" size="5" />
      <span class="nav-label ml-2">Manage Buildings</span>
    </router-link> -->
    <router-link 
      to="/managelicenses"
      class="p-2 rounded hover:text-blue flex items-center" 
      v-if="user.isAdmin"

      :class="activeRoute === '/managelicenses' ? 'text-blue': 'text-secondary'"
    > 
      <icon icon="license" size="5" />
      <span class="nav-label ml-2">Manage Licenses</span>
    </router-link>

  </nav>
</template>

<script>
import Icon from '@/components/Icon'
import { mapState } from 'vuex'


export default {
  name: "SideNav",
  components: {
    Icon
  },
  computed: {
    activeRoute() {
      return this.$route.path
    },
    ...mapState(['user'])
  }
}
</script>

<style>
  .symphony-cycle-nav .router-link-exact-active{
    background-color: rgba(35, 42, 63, .05);
  }
</style>