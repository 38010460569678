<template>
  <symbol
    id="icon-close"
    viewBox="0 0 18 18"
  >
    <path d="M15 3L3 15" stroke="currentColor" fill="transparent" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 3L15 15" stroke="currentColor" fill="transparent" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>
</template>

<script>
export default {
  name: 'IconClose'
}
</script>