<template>
  <symbol
    id="icon-home"
    viewBox="0 0 18 18"
  >
    <path d="M10.6875 14.6272V11.2522C10.6875 11.103 10.6282 10.9599 10.5227 10.8544C10.4173 10.7489 10.2742 10.6897 10.125 10.6897H7.875C7.72582 10.6897 7.58274 10.7489 7.47725 10.8544C7.37176 10.9599 7.3125 11.103 7.3125 11.2522V14.6272C7.3125 14.7763 7.25324 14.9194 7.14775 15.0249C7.04226 15.1304 6.89918 15.1897 6.75 15.1897H3.375C3.22582 15.1897 3.08274 15.1304 2.97725 15.0249C2.87176 14.9194 2.8125 14.7763 2.8125 14.6272V8.12326C2.81376 8.04541 2.83058 7.96861 2.86198 7.89736C2.89337 7.82612 2.93871 7.76187 2.99531 7.70841L8.62031 2.5967C8.72401 2.50183 8.85946 2.44922 9 2.44922C9.14054 2.44922 9.27599 2.50183 9.37969 2.5967L15.0047 7.70841C15.0613 7.76187 15.1066 7.82612 15.138 7.89736C15.1694 7.96861 15.1862 8.04541 15.1875 8.12326V14.6272C15.1875 14.7763 15.1282 14.9194 15.0227 15.0249C14.9173 15.1304 14.7742 15.1897 14.625 15.1897H11.25C11.1008 15.1897 10.9577 15.1304 10.8523 15.0249C10.7468 14.9194 10.6875 14.7763 10.6875 14.6272Z" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
  </symbol>
</template>

<script>
export default {
  name: 'IconHome'
}
</script>