<template>
  <symbol
    id="icon-location"
    viewBox="0 0 18 18"
  >
    <path d="M9 9.5625C10.2426 9.5625 11.25 8.55514 11.25 7.3125C11.25 6.06986 10.2426 5.0625 9 5.0625C7.75736 5.0625 6.75 6.06986 6.75 7.3125C6.75 8.55514 7.75736 9.5625 9 9.5625Z" stroke="currentColor" fill="transparent" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.625 7.3125C14.625 12.375 9 16.3125 9 16.3125C9 16.3125 3.375 12.375 3.375 7.3125C3.375 5.82066 3.96763 4.38992 5.02252 3.33502C6.07742 2.28013 7.50816 1.6875 9 1.6875C10.4918 1.6875 11.9226 2.28013 12.9775 3.33502C14.0324 4.38992 14.625 5.82066 14.625 7.3125V7.3125Z" stroke="currentColor" fill="transparent" stroke-width="1.13" stroke-linecap="round" stroke-linejoin="round" />
  </symbol>
</template>

<script>
export default {
  name: 'IconLocation'
}
</script>