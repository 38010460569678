<template>
  <header class="fixed top-0 left-0 z-10 bg-white py-3 px-8 w-full h-16 shadow-md">
    <div class="container max-w-7xl mx-auto flex items-center justify-between">
      <a 
        href="https://www.symphonyenergy.com/" 
        class="block w-28"
      >
        <img 
          src="@/assets/symphony-logo.svg" 
          alt="Symphony" 
          class="w-full h-auto"
        />
      </a>
      <div class="pl-4 relative" v-click-outside="closeNav">
        <div 
          @click="toggleNav"
          class="avatar w-10 h-10 rounded-lg bg-blue-light flex items-center justify-center text-sm text-blue font-semibold cursor-pointer"
        >
          {{ userInitials }}
        </div>
        <div 
          class="absolute right-0 top-full mt-3 w-50"
          :class="{ 'hidden': !open }"
        >
          <div class="arrow absolute z-10 w-2 h-2 -top-1 right-3 rounded-sm bg-white transform rotate-45 border-t border-l border-light-grey"></div>
          <div class="rounded-lg shadow-lg bg-white border border-light-grey">
            <div class="flex flex-col border-b border-light-grey py-4 px-6">
              <span class="font-medium text-ellipsis overflow-hidden">{{ userName }}</span>
              <span class="text-sm text-secondary text-ellipsis ">{{ userEmail }}</span>
            </div>
            <div class="py-4">
              <router-link class="text-sm py-2 px-6 text-secondary hover:text-blue" to="/account" v-if="user"> 
                Account
              </router-link>
              <button 
                @click="logoutUser"
                class="block py-2 px-6 text-sm text-secondary hover:text-blue"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "AppHeader",
  data: () => ({
    open: false,
  }),
  computed: {
    userName () {
      return this.user ? this.user.representative_name : ''
    },
    userEmail () {
      return this.user ? this.user.representative_email : ''
    },
    userInitials () {
      if (!this.user) {
        return ''
      }
      // Return only the initial letters from the first and last name, even if the user has more than two names.
      const names = this.userName.split(' ')
      const firstName = names[0].toUpperCase()
      const lastName = names[names.length - 1].toUpperCase()

      return `${firstName[0]}${lastName[0]}`
    },
    ...mapState(['user'])
  },
  methods: {
    toggleNav () {
      this.open = !this.open
    },
    closeNav () {
      this.open = false
    },
    ...mapActions(['logoutUser'])
  },
}
</script>

<style>

</style>