<template>
  <div
    class="w-full rounded-2xl border border-light-grey bg-dark-grey shadow p-20"
  >
    <!-- <slot /> -->
    <section-wrapper>
      <!-- <section-header title="Payment pending" condensed /> -->
      <header class="text-blue">
        <h1 class="text-4xl text-blue font-medium mb-4">Payment pending</h1>
      </header>
      <div class="text-blue font-light">
        <p class="mb-4">
          You will have access to the implementation details for Symphony Cycle
          once your payment has been confirmed. We will notify you of this by
          email.
        </p>
        <!-- <button class="rounded-full ...">Save Changes</button> -->
        <button
          class="
            py-3
            px-4
            text-sm
            leading-3
            rounded-md
            shadow
            text-white
            bg-blue
            border border-light-grey
            whitespace-nowrap
          "
          @click="this.$router.push({ name: 'Billing' })"
        >
          Go To Billing
        </button>
      </div>
    </section-wrapper>
  </div>
</template>

<script>
import SectionWrapper from "./SectionWrapper";

export default {
  components: {
    SectionWrapper,
  },
  name: "DeniedAccess",
};
</script>