import { createRouter, createWebHistory } from 'vue-router'
import Login from "@/components/Login"
import ForgotPassword from "@/components/ForgotPassword"
import ChangePassword from "@/components/ChangePassword"
import Dashboard from "@/components/Dashboard"
import ClientDetails from "@/components/ClientDetails"
import Home from "@/components/Home"
import Billing from "@/components/Licenses/Billing"
import Implementation from "@/components/Implementation"
import ManageLicenses from "@/components/ManageLicenses"
import store from '@/store'

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: ChangePassword
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home
      },
      {
        path: "/account",
        name: "Client Details",
        component: ClientDetails
      },
      {
        path: "/billing",
        name: "Billing",
        component: Billing
      },
      {
        path: "/implementation",
        name: "Implementation",
        component: Implementation
      },
      {
        path: "/managelicenses",
        name: "ManageLicenses",
        component: ManageLicenses
      }
    ]
  }
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach(async (to) => {
  // console.log(to);
  if (
    // make sure the user is authenticated
    !store.state.uid &&
    // ❗️ Avoid an infinite redirect
    (to.name !== 'Login' && to.name !== 'ForgotPassword' && to.name !== 'ChangePassword')
  ) {
    // redirect the user to the login page
    return { name: 'Login' }
  }
  // if (
  //   // make sure the user is authenticated
  //   !store.state.uid &&
  //   // ❗️ Avoid an infinite redirect
  //   (to.name == 'ForgotPassword')
  // ) {
  //   // redirect the user to the login page
  //   return { name: 'ForgotPassword' }
  // }

  // if (
  //   store.state.user &&
  //   store.state.user.isAdmin &&
  //   ['Client Details', 'Billing', 'Implementation'].includes(to.name)
  // ) {
  //   return { name: 'Home' }
  // }
  if (
    store.state.user &&
    !store.state.user.isAdmin &&
    ['ManageLicenses'].includes(to.name)
  ) {
    return { name: 'Home' }
  }
})

export default router