<template>
  <div>
    <div>
      <header class="mb-4">
        <h3 class="mb-2 font-medium text-blue">Address</h3>
      </header>
      <div class="grid grid-cols-1 gap-8">
        <input-field
          label="Building Name"
          v-model="name"
          :isValid="this.v$.name.$invalid ? false : true"
        >
          <template #error v-if="this.v$.name.$invalid"
            >{{ v$.name.required.$message }}
          </template>
        </input-field>
        <input-field
          label="Office Address Line 1"
          v-model="address1"
          :isValid="this.v$.address1.$invalid ? false : true"
        >
          <template #error v-if="this.v$.address1.$invalid"
            >{{ v$.address1.$silentErrors[0].$message }}
          </template>
        </input-field>
        <input-field label="Office Address Line 2" v-model="address2" />
        <div class="w-full grid gap-4 grid-cols-6">
          <div class="col-span-2">
            <input-field
              label="Postal / Zip Code"
              v-model="zip"
              :isValid="this.v$.zip.$invalid ? false : true"
            >
              <template #error v-if="this.v$.zip.$invalid"
                >{{ v$.zip.$silentErrors[0].$message }}
              </template>
            </input-field>
          </div>
          <div class="col-span-4">
            <input-field
              label="City"
              v-model="city"
              :isValid="this.v$.city.$invalid ? false : true"
            >
              <template #error v-if="this.v$.city.$invalid"
                >{{ v$.city.$silentErrors[0].$message }}
              </template>
            </input-field>
          </div>
        </div>
        <dropdown
          label="Country"
          placeholder="Choose a country"
          v-model="country"
          :options="countries"
        />
        <input-field
          label="Google Maps Link"
          v-model="google_maps_link"
          :isValid="this.v$.google_maps_link.$invalid ? false : true"
        >
          <template #error v-if="this.v$.google_maps_link.$invalid"
            >{{ v$.google_maps_link.$silentErrors[0].$message }}
          </template>
        </input-field>
      </div>
    </div>
    <div class="mt-16">
      <header class="mb-4">
        <h3 class="mb-2 font-medium text-blue">Building Details</h3>
      </header>
      <div class="grid gap-8">
        <dropdown
          label="Building Type"
          placeholder="Choose a type"
          v-model="type"
          :options="buildingTypes"
        />
        <input-field
          v-if="type === 'non-office'"
          type="number"
          label="Installed fresh air ventilation capacity"
          v-model="fresh_air_ventilation_capacity"
          :isValid="
            this.v$.fresh_air_ventilation_capacity.$invalid ? false : true
          "
        >
          <template
            #error
            v-if="this.v$.fresh_air_ventilation_capacity.$invalid"
            >{{ v$.fresh_air_ventilation_capacity.$silentErrors[0].$message }}
          </template>
        </input-field>
        <input-field
          type="number"
          label="Days of operation per week"
          v-model="normal_days_operation"
          :isValid="this.v$.normal_days_operation.$invalid ? false : true"
        >
          <template #error v-if="this.v$.normal_days_operation.$invalid"
            >{{ v$.normal_days_operation.$silentErrors[0].$message }}
          </template>
        </input-field>
        <input-field
          type="number"
          label="Average hours of operation per day"
          v-model="hours_operation_per_day"
          :isValid="this.v$.hours_operation_per_day.$invalid ? false : true"
        >
          <template #error v-if="this.v$.hours_operation_per_day.$invalid"
            >{{ v$.hours_operation_per_day.$silentErrors[0].$message }}
          </template>
        </input-field>
        <div class="grid gap-4 grid-cols-6">
          <div class="col-span-4">
            <input-field
              type="number"
              label="Gross Building Area"
              v-model="gross_building_area"
              :isValid="this.v$.gross_building_area.$invalid ? false : true"
            >
              <template #error v-if="this.v$.gross_building_area.$invalid"
                >{{ v$.gross_building_area.$silentErrors[0].$message }}
              </template>
            </input-field>
          </div>
          <div class="col-span-2">
            <dropdown
              label="Gross building area unit"
              v-model="gross_building_area_unit"
          placeholder="Choose a unit"
              :options="areaUnits"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { countries, areaUnits, buildingTypes } from "@/store/constants";
import InputField from "@/components/Library/Input";
import Dropdown from "@/components/Library/Dropdown";
import useVuelidate from "@vuelidate/core";
import { required, numeric, between, helpers } from "@vuelidate/validators";

const mustBeGoogleMapLink = (value) =>
  !helpers.req(value) ||
  value.includes("google.com/maps/") ||
  value.includes("maps.app.goo");

export default {
  name: "BuildingCreate",
  components: {
    InputField,
    Dropdown,
  },
  data() {
    return {
      countries,
      areaUnits,
      buildingTypes,
      saving: false,
      isValid: true,
    };
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  validations() {
    return {
      name: {
        required: helpers.withMessage("This field cannot be empty", required),
      },
      address1: {
        required: helpers.withMessage("This field cannot be empty", required),
      },
      zip: {
        required: helpers.withMessage("This field cannot be empty", required),
      },
      city: {
        required: helpers.withMessage("This field cannot be empty", required),
      },
      country: {
        required: helpers.withMessage("This field cannot be empty", required),
      },
      google_maps_link: {
        required: helpers.withMessage("This field cannot be empty", required),
        mustBeGoogleMapLink: helpers.withMessage(
          "This field must be a valid Google Maps URL",
          mustBeGoogleMapLink
        ),
      },
      type: {
        required: helpers.withMessage("This field cannot be empty", required),
      },
      normal_days_operation: {
        required: helpers.withMessage("This field cannot be empty", required),
        numeric,
        between: between(1, 7),
      },
      hours_operation_per_day: {
        required: helpers.withMessage("This field cannot be empty", required),
        numeric,
        between: between(1, 24),
      },
      gross_building_area: {
        required: helpers.withMessage("This field cannot be empty", required),
        numeric,
        between: between(100, 5000000),
      },
      fresh_air_ventilation_capacity: {
        required: helpers.withMessage("This field cannot be empty", required),
        numeric,
        between: between(10, 1000),
      },
      gross_building_area_unit: {
        required: helpers.withMessage("This field cannot be empty", required),
      },
    };
  },
  computed: {
    name: {
      get() {
        return this.newBuilding.name;
      },
      set(val) {
        this.setNewBuildingProp({ prop: "name", value: val });
        this.v$.name.$touch();
        this.creatingIsValid();
      },
    },
    address1: {
      get() {
        return this.newBuilding.address1;
      },
      set(val) {
        this.setNewBuildingProp({ prop: "address1", value: val });
        this.v$.address1.$touch();
        this.creatingIsValid();
      },
    },
    address2: {
      get() {
        return this.newBuilding.address2;
      },
      set(val) {
        this.setNewBuildingProp({ prop: "address2", value: val });
      },
    },
    zip: {
      get() {
        return this.newBuilding.zip;
      },
      set(val) {
        this.setNewBuildingProp({ prop: "zip", value: val });
        this.v$.zip.$touch();
        this.creatingIsValid();
      },
    },
    city: {
      get() {
        return this.newBuilding.city;
      },
      set(val) {
        this.setNewBuildingProp({ prop: "city", value: val });
        this.v$.city.$touch();
        this.creatingIsValid();
      },
    },
    country: {
      get() {
        const countryCodes = Object.keys(this.countries);
        const countryNames = Object.values(this.countries);

        if (countryCodes.includes(this.newBuilding.country)) {
          return this.newBuilding.country;
        } else {
          const countryIndex = countryNames.indexOf(this.newBuilding.country);
          return countryCodes[countryIndex];
        }
      },
      set(val) {
        this.setNewBuildingProp({ prop: "country", value: val });
        this.v$.country.$touch();
        this.creatingIsValid();
      },
    },
    google_maps_link: {
      get() {
        return this.newBuilding.google_maps_link;
      },
      set(val) {
        this.setNewBuildingProp({ prop: "google_maps_link", value: val });
        this.v$.google_maps_link.$touch();
        this.creatingIsValid();
      },
    },
    type: {
      get() {
        return this.newBuilding.type;
      },
      set(val) {
        this.setNewBuildingProp({ prop: "type", value: val });
        this.v$.type.$touch();
        this.creatingIsValid();
      },
    },
    normal_days_operation: {
      get() {
        return this.newBuilding.normal_days_operation;
      },
      set(val) {
        this.setNewBuildingProp({ prop: "normal_days_operation", value: val });
        this.v$.normal_days_operation.$touch();
        this.creatingIsValid();
      },
    },
    hours_operation_per_day: {
      get() {
        return this.newBuilding.hours_operation_per_day;
      },
      set(val) {
        this.setNewBuildingProp({ prop: "hours_operation_per_day", value: val });
        this.v$.hours_operation_per_day.$touch();
        this.creatingIsValid();
      },
    },
    gross_building_area: {
      get() {
        return this.newBuilding.gross_building_area;
      },
      set(val) {
        this.setNewBuildingProp({ prop: "gross_building_area", value: val });
        this.v$.gross_building_area.$touch();
        this.creatingIsValid();
      },
    },
    gross_building_area_unit: {
      get() {
        return this.newBuilding.gross_building_area_unit;
      },
      set(val) {
        this.setNewBuildingProp({ prop: "gross_building_area_unit", value: val });
        this.v$.gross_building_area_unit.$touch();
        this.creatingIsValid();
      },
    },
    fresh_air_ventilation_capacity: {
      get() {
        if (this.newBuilding.type == "office") {
          return 100;
        }
        return this.newBuilding.fresh_air_ventilation_capacity;
      },
      set(val) {
        this.setNewBuildingProp({
          prop: "fresh_air_ventilation_capacity",
          value: val,
        });
        this.v$.fresh_air_ventilation_capacity.$touch();
        this.creatingIsValid();
      },
    },
    ...mapState(["newBuilding"]),
    ...mapGetters(["getEditingIsValid"]),
  },
  methods: {
    creatingIsValid() {
      if (this.v$.$errors.length > 0) this.SetCreatingIsValid(false);
      else this.SetCreatingIsValid(true);
    },
    ...mapMutations(["setNewBuildingProp", "SetCreatingIsValid"]),
  },
  mounted() {
    this.v$.$validate();
    this.creatingIsValid();
  },
};
</script>

<style scoped>
/* * {
  border: 1px solid black;
} */
</style>