<template>
  <loading v-if="loading" />
  <div v-if="!loading">
    <header class="mb-6">
      <h1 class="text-4xl text-blue font-medium mb-4">Billing</h1>
      <p class="font-light text-blue">Review your billing details.</p>
    </header>
    <section-wrapper>
      <section-header
        title="Symphony license"
        description="As your building(s) will continue to benefit from Symphony Cycle in the years ahead, your Symphony Cycle license will renew annually. Should you choose to remove the operation of Symphony Cycle from your building, please get in touch."
      >
        <div class="text-blue flex flex-col justify-end">
          <div class="currency flex items-end justify-end">
            <span class="symbol text-2xl font-medium">{{
              licensesCurrency
            }}</span>
            <span class="amount text-2xl font-medium">{{ totalAmount }} </span>
            <span class="unit text-sm mb-0.5 font-light">{{ "+VAT" }}</span>
          </div>
          <div class="currency flex items-end justify-end">
            <span class="symbol text-3xl font-medium">{{
              licensesCurrency
            }}</span>
            <span class="amount text-3xl font-medium">{{
              totalAmountWithVAT
            }}</span>
            <span class="unit text-sm mb-0.5 font-light">{{ "TOTAL" }}</span>
          </div>
          <div class="text-sm font-light text-right">
            / {{ renewalPeriodString }}
          </div>
        </div>
      </section-header>
    </section-wrapper>
    <section-wrapper>
      <section-header
        title="Payment Details"
        description="Use the information below to pay for your licences."
      />
      <table class="text-left text-blue">
        <tbody>
          <tr v-for="(row, index) in currentBillingDetails" :key="index">
            <th class="py-2 font-medium">{{ row.label }}</th>
            <td class="py-2 pl-8 font-light">{{ row.value }}</td>
          </tr>
          <tr>
            <th class="py-2 font-medium">Reference ID</th>
            <td class="py-2 pl-8 font-light">{{ user.id }}</td>
          </tr>
        </tbody>
      </table>
    </section-wrapper>
    <section class="mt-8">
      <header class="mb-6">
        <h2 class="text-2xl text-blue font-medium mb-4">Licenses</h2>
        <p class="font-light text-blue">
          View your current Symphony Cycle licenses.
        </p>
      </header>
      <div class="licenses-list grid gap-3">
        <license-item
          v-for="(license, index) in licenses"
          :key="index"
          :license="license"
        />
      </div>
    </section>

    <modal-wrapper title="License Agreement" v-if="false" @close="closeEditBuilding">
    </modal-wrapper>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from "../Loading";
import SectionWrapper from "../Layout/SectionWrapper";
import SectionHeader from "../Layout/SectionHeader";
import LicenseItem from "./LicenseItem";
import ModalWrapper from "@/components/Layout/ModalWrapper";

export default {
  name: "BillingHome",
  components: {
    Loading,
    SectionWrapper,
    ModalWrapper,
    SectionHeader,
    LicenseItem,
  },
  data: () => ({
    loading: false,
    billingDetails: {
      default: {
        bankName: {
          label: "Bank name",
          value: "AIB Bank, 52 Upper Baggot St, Dublin 4, Ireland",
        },
        accountName: {
          label: "Account Name",
          value: "Xperen Ltd",
        },
        accountNumber: {
          label: "Account Number",
          value: "25453069",
        },
        sortCode: {
          label: "Sort Code",
          value: "93-10-63",
        },
        iban: {
          label: "IBAN",
          value: "IE86 AIBK 9310 6325 4530 69",
        },
        swift: {
          label: "Swift/ BIC",
          value: "AIBKIE2D",
        },
      },
      uk: {
        bankName: {
          label: "Bank name",
          value: "Bank of Ireland UK",
        },
        accountName: {
          label: "Account Name",
          value: "Symphony Energy Controls Ltd",
        },
        accountNumber: {
          label: "Account Number",
          value: "89198913",
        },
        sortCode: {
          label: "Sort Code",
          value: " 90-48-86",
        },
        iban: {
          label: "IBAN",
          value: "GB04 BOFI 9048 8689 1989 13",
        },
        swift: {
          label: "BIC",
          value: "BOFIGB2B",
        },
      },
    },
  }),
  mounted() {
    // if (!this.licenses.length) {
    //   this.loading = true;
    // }
    this.getLicenses().then(() => {
      this.loading = false;
    });
  },
  computed: {
    currentUserCountry() {
      return this.user ? this.user.country.toLowerCase() : null;
    },
    currentBillingDetails() {
      if (this.currentUserCountry === "uk") {
        return this.billingDetails.uk;
      } else {
        return this.billingDetails.default;
      }
    },
    totalAmount() {
      if (!this.licenses) {
        return null;
      }

      const num = new Intl.NumberFormat();

      return num.format(
        this.licenses
          .reduce((total, license) => {
            return total + license.cost.amount;
          }, 0)
          .toFixed(0)
      );
    },
    totalAmountWithVAT() {
      if (!this.licenses) {
        return null;
      }

      const num = new Intl.NumberFormat();

      return num.format(
        this.licenses
          .reduce((total, license) => {
            return (
              total + license.cost.amount * (1 + (license.cost.vat || 23) / 100)
            );
          }, 0)
          .toFixed(0)
      );
    },
    licensesCurrency() {
      if (!this.licenses[0]) {
        return null;
      }
      // console.log(this.licenses);
      return this.licenses[0].cost.symbol;
    },
    licensesUnit() {
      if (!this.licenses[0]) {
        return null;
      }
      return this.licenses[0].cost.unit;
    },
    renewalPeriod() {
      if (!this.licenses[0]) {
        return null;
      }
      return this.licenses[0].renewal_period;
    },
    renewalPeriodString() {
      if (!this.renewalPeriod) {
        return null;
      }
      return this.renewalPeriod === 12 ? "Annually" : "Monthly";
    },
    ...mapState(["licenses", "user"]),
  },
  methods: {
    ...mapActions(["getLicenses"]),
  },
};
</script>


<style>
* {
  border: 1px solid black;
}
</style>