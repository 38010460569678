<template>
  <section-wrapper>
    <div 
      class="flex items-center justify-between cursor-pointer text-blue" 
      @click="toggle"
    >
      <div class="mr-8">
        <h3 class="font-medium mb-2" v-html="title"></h3>
      </div>
      <div 
        class="transform"
        :class="{'rotate-180': open }"
      >
        <icon icon="chevron-down" size="5" />
      </div>
    </div>
    <div class="mt-2 text-blue font-light" v-show="open">
      <slot></slot>
    </div>
  </section-wrapper>
</template>

<script>
import SectionWrapper from './Layout/SectionWrapper'
import Icon from './Icon'

export default {
  name: 'FaqItem',
  components: {
    SectionWrapper,
    Icon
  },
  props: {
    title: {
      type: String,
      required: true
    },
  },
  data: () => ({
    open: false
  }),
  methods: {
    toggle () {
      this.open = !this.open
    }
  }
}
</script>