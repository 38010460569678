<template>
  <header 
    class="text-blue"
    :class="condensed ? 'mb-0' : 'mb-6'"
  >
    <h2 class="font-medium mb-2">{{ title }}</h2>
    <div class="flex items-center justify-between">
      <div class="mr-8">
        <p class="text-sm font-light" v-html="description"></p>
      </div>
      <slot></slot>
    </div>
  </header>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    condensed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
}
</script>