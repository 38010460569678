<template>
  <symbol
    id="icon-building"
    viewBox="0 0 18 18"
  >
    <path d="M1.125 15.1875H16.875" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M10.125 15.1875V2.8125C10.125 2.66332 10.0657 2.52024 9.96025 2.41475C9.85476 2.30926 9.71168 2.25 9.5625 2.25H2.8125C2.66332 2.25 2.52024 2.30926 2.41475 2.41475C2.30926 2.52024 2.25 2.66332 2.25 2.8125V15.1875" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M15.75 15.1875V7.3125C15.75 7.16332 15.6907 7.02024 15.5852 6.91475C15.4798 6.80926 15.3367 6.75 15.1875 6.75H10.125" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M4.5 5.0625H6.75" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M5.625 9.5625H7.875" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M4.5 12.375H6.75" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M12.375 12.375H13.5" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M12.375 9.5625H13.5" stroke="currentColor" fill="transparent" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"></path>
  </symbol>
</template>

<script>
export default {
  name: 'IconBuilding'
}
</script>